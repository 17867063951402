// //--------------------------------------------------------------------------------------------------------------------------------------------------------------
// // Main Navigation
// //--------------------------------------------------------------------------------------------------------------------------------------------------------------

$QLD-main-nav-breakpoint: md !default;
$QLD-main-nav-width: 320px !default;
$QLD-main-nav-z-index: 200 !default;
$QLD-header-sm: 52px;
$QLD-header-md: 72px;



.qld__main-nav {
    @include QLD-fontgrid(sm);
    border-bottom: $QLD-border-width-thick solid var(--QLD-color-light-designAccent);

    @include QLD-media(lg) {
        background-color: var(--QLD-color-light-background__shade);
        border-bottom-width: $QLD-border-width-extra-thick;
    }

    .qld__main-nav__no-desktop{
        @include QLD-media(lg) {
            border: 0;
            clip: rect(1px, 1px, 1px, 1px);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }


    // The content area that becomes the menu menu
    .qld__main-nav__menu {
        &::before {
            content: "";
            display: block;
            @include QLD-space(width, 0.25unit);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            
            @include QLD-media(lg) {
                display: none;
            }
        }
        // Move the menu content off the screen so it can slide in
        .js & {
            background-color: var(--QLD-color-light-background__shade);
            display: none;
            overflow: auto;
            position: fixed;
            top: 0;
            bottom: 0;
            // right: 0;
            right: -1 * $QLD-main-nav-width;
            width: $QLD-main-nav-width;
            z-index: $QLD-main-nav-z-index;
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
            
            @include QLD-media(md) {
                @include QLD-space(width, 26.25unit);
            }
            @include QLD-media(lg) {
                display: block;
                position: static;
                box-shadow: none;
                width: auto;    
                overflow: visible;
            }
        }

        // Menu items
        & .qld__main-nav__item {
            width: 100%;
            margin: 0; 
            a{
                @include QLD-underline('light','noUnderline','default','noVisited');
                &:hover{                   
                    .qld__icon{
                        color: var(--QLD-color-light-alt-button__hover);
                    }
                }
            }

            .qld__icon{
                color: var(--QLD-color-light-alt-button);
            }

           

            @include QLD-media(lg) {
                width: auto;
                float: left;
                border: none;
            }

            &.active .qld__main-nav__item-link {
                
                font-weight: bold;
                color: var(--QLD-color-light-text);
                border-left-color: transparent;

                @include QLD-media(lg) {
                    // border-bottom-color: transparent;
                    border-bottom: solid $QLD-border-width-extra-thick var(--QLD-color-light-background__shade);
                    @include QLD-space(margin-bottom, -.5unit);
                }
                
                &:hover {
                    border-color: var(--QLD-color-light-button__hover);
                    color: var(--QLD-color-light-link);
                }
            }
        }


        & .qld__main-nav__cta-wrapper{

            .qld__main-nav__item--cta{

                .qld__main-nav__item-title::before{
                    background-color: var(--QLD-color-light-border);
                    top: 0;
                    bottom: auto;
                    left: 0;       
                }

                .qld__main-nav__item-link{
                    color: var(--QLD-color-light-link);
                    border: none;

                    a{
                        @include QLD-underline('light','noUnderline','default','noVisited');
                    }
    
                    &:hover{
                        background-color: var(--QLD-color-light-background);

                        .qld__icon{
                            color: var(--QLD-color-light-alt-button__hover);
                        }
                    }
                }

                .qld__icon{
                    color: var(--QLD-color-light-alt-button);
                }
            }

            .qld__link-list{
                background-color: var(--QLD-color-light-background__shade);
            }
        }

        // Menu item title
        & .qld__main-nav__item-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative; 
            @include QLD-fontgrid(sm, heading);
            height: 100%;

            &::before{
                content: '';
                display: block;
                @include QLD-space(height, .0625unit);
                position: absolute;
                bottom: 0;
                left: 4px;
                right: 0;
                background-color: var(--QLD-color-light-border);

                @include QLD-media(lg) {
                    @include QLD-space(height, 0);
                }
            }
           
        }
        // Menu item link
        & .qld__main-nav__item-link {
            width: 100%;
            @include QLD-space(padding, 1unit 1unit 1unit 0.75unit);
            border-left: solid $QLD-border-width-thick var(--QLD-color-light-designAccent);
            display: flex;
            align-items: center;
            color: var(--QLD-color-light-text);

            @include QLD-media(md) {
                @include QLD-space(padding, 1.25unit 1unit 1.25unit 0.75unit);
            }

            @include QLD-media(lg) {
                border-left: none;
                @include QLD-space(padding, .625unit .5unit .625unit .5unit);
                @include QLD-space(line-height, 2unit);
            }

            @include QLD-media(xl) {
                @include QLD-space(padding, .625unit .75unit .625unit .75unit);
            }
            

            &:hover {
                color: var(--QLD-color-light-link);
                background-color: var(--QLD-color-light-alt-background);
                border-left: $QLD-border-width-thick solid var(--QLD-color-light-button);

                
                @include QLD-media(lg) {
                    border-bottom: $QLD-border-width-extra-thick solid var(--QLD-color-light-button__hover);
                    border-left: none;
                    @include QLD-space(margin-bottom, -0.5unit);
                }
                
                .qld__icon{
                    color: var(--QLD-color-light-alt-button__hover);
                }
                

            }

            &:active {
                color: var(--QLD-color-light-text);
                background-color: var(--QLD-color-light-background);
                border-left-color: var(--QLD-color-light-background__shade);
                font-weight: bold;

                .qld__main-nav__item-icon{
                    stroke-width: 10px;
                }
            
                
                @include QLD-media(lg) {
                    @include QLD-space(
                        border-bottom,
                        0.5unit solid var(--QLD-color-light-designAccent)  
                    );
                    @include QLD-space(margin-bottom, -0.5unit);
                }
                
            }
            
            
        }
        // Menu item icon
        & .qld__main-nav__item-link .qld__icon {
            @include QLD-space(margin-right, .5unit);
            display: inline-block;
            color: var(--QLD-color-light-alt-button);

            @include QLD-media(lg) {
                @include QLD-space(height, 2unit);
                @include QLD-space(width, 1.5unit);
                vertical-align: bottom;
                margin-right: 0;
            }
        }

        & .qld__main-nav__item-text {
            display: inline-flex;
            flex-direction: column;
            &::before{
                content: attr(data-name) / "";
                height: 0;
                visibility: hidden;
                overflow: hidden;
                user-select: none;
                pointer-events: none;
                font-weight: bold;
            }
        }

        &.qld__main-nav__menu--dark{
            background-color: var(--QLD-color-dark-background__shade);

            .qld__main-nav__header{
                background-color: var(--QLD-color-dark-background);
            }
            .qld__main-nav__menu-heading{
                color: var(--QLD-color-dark-heading);
            }
            .qld__main-nav__toggle{
                color: var(--QLD-color-dark-link);
                border-color: var(--QLD-color-dark-border);

                .qld__icon{
                    color: var(--QLD-color-dark-alt-button);
                }
                &:hover{
                    background-color: var(--QLD-color-dark-background__shade);

                    .qld__icon{
                        color: var(--QLD-color-dark-alt-button__hover);
                    }
                }
            }

            .qld__main-nav__cta-wrapper{
                .qld__link-list{
                    background-color: var(--QLD-color-dark-background__shade);
                }

                .qld__main-nav__item-title::before{
                    background-color: var(--QLD-color-dark-border);
                }

                .qld__main-nav__item--cta{
                    .qld__main-nav__item-link{
                        color: var(--QLD-color-dark-link);

                        &:hover{
                            background-color: var(--QLD-color-dark-background);

                            .qld__icon{
                                color: var(--QLD-color-dark-alt-button__hover);
                            }
                        }
                    }

                    .qld__icon{
                        color: var(--QLD-color-dark-alt-button);
                    }
                } 
            }
        }

        &.qld__main-nav__menu--dark-alt{
            background-color: var(--QLD-color-dark-alt-background__shade);

            .qld__main-nav__header{
                background-color: var(--QLD-color-dark-alt-background);
            }
            .qld__main-nav__menu-heading{
                color: var(--QLD-color-dark-heading);
            }

            .qld__main-nav__toggle{
                color: var(--QLD-color-dark-link);
                border-color: var(--QLD-color-dark-alt-border);

                .qld__icon{
                    color: var(--QLD-color-dark-alt-button);
                }

                &:hover{
                    background-color: var(--QLD-color-dark-alt-background__shade);
                    
                    .qld__icon{
                        color: var(--QLD-color-dark-alt-button__hover);
                    }
                }
            }

            .qld__main-nav__cta-wrapper{
                .qld__link-list{
                    background-color: var(--QLD-color-dark-alt-background__shade);
                }

                .qld__main-nav__item-title::before{
                    background-color: var(--QLD-color-dark-alt-border);
                }

                .qld__main-nav__item--cta{
                    .qld__main-nav__item-link{
                        color: var(--QLD-color-dark-link);
                        
                        @include QLD-underline('dark','noUnderline','default','noVisited');

                        &:hover{
                            background-color: var(--QLD-color-dark-alt-background);
                            
                            .qld__icon{
                                color: var(--QLD-color-dark-alt-button__hover);
                            }
                        }
                    }

                    .qld__icon{
                        color: var(--QLD-color-dark-alt-button);
                    }
                } 
            }

        }
    }
    
        // The overlay that shows when the menu is open
    .qld__main-nav__overlay {
        transition: opacity 0.25s ease-out;
        background-color: var(--QLD-color-dark-alt-background__shade);
        cursor: pointer;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: $QLD-main-nav-z-index - 10;
        opacity: 0.4;
        display: none; // Hidden by default
        
        .ie8 & {
            filter: alpha(opacity=80);
        }
    }

        // Open and close functionality
    .js & .qld__main-nav__content.qld__main-nav__content--open {
        
        // Show the content when the menu gets opened
        .qld__main-nav__menu {
            right: 0;
            display: block;
        }
        
        // Move the overlay and animate the transparency
        .qld__main-nav__overlay {
            opacity: 0.95;
            right: 0;
            display: block;
            
            // Hide on desktop
            @include QLD-media($QLD-main-nav-breakpoint) {
                display: none;
            }
        }
    }

    &__menu-inner{
        position: relative;
        height: 100%;
    }

    &__cta-wrapper{
        position: fixed;
        bottom: 0;
        width: 100%;

        @include QLD-media(lg) {
            display: none;
        }
    }

    &__header {
        display: flex;
        background-color: var(--QLD-color-light-background);
        color: var(--QLD-color-light-text);
        align-items: center;
        @include QLD-box-shadow(1);
        position: relative;

        @include QLD-media(lg) {
            display: none;
        }

        .qld__main-nav__toggle{
            margin-left: auto;
        }


    }

    & &__menu-heading{
        @include QLD-fontgrid(xs);
        @include QLD-space(margin, 0unit 1unit);
        // @include QLD-space(padding, 1unit 0unit);
        font-weight: normal;
        color: var(--QLD-color-light-heading);
        
        @include QLD-media(md) {
            @include QLD-fontgrid(sm);
            @include QLD-space(line-height, 4.5unit);
        }
        
        @include QLD-media(lg) {
            display: none;

            .qld__body & {
                display: none;
            }
        }  
    }

    // Menu open and close toggles
    &__toggle{
        display: flex;
        flex-direction: column;
        @include QLD-space(padding, 0.5unit 1unit 0.25unit 1unit);
        @include QLD-fontgrid(xs, nospace);
        border: 0;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        align-items: center;
        color: var(--QLD-color-light-link);
        @include QLD-focus();
        border-left: solid $QLD-border-width-thin var(--QLD-color-light-border);
        
        &:hover {
            background-color: var(--QLD-color-light-background__shade);

            svg{
                color: var(--QLD-color-light-alt-button__hover);
            }
        }

        @include QLD-media(md) {
            @include QLD-space(padding, 0.75unit 1unit);
        }

        .qld__main-nav__toggle-text{
            @include QLD-space(font-size, .625unit);
            @include QLD-space(margin-top, .25unit);
            line-height: 1rem;

            @include QLD-media(md) {
                @include QLD-space(margin-top, .625unit);
                @include QLD-fontgrid(xs, nospace);
            }

        }

        &:before {
            @include QLD-fontgrid(sm, nospace);
            @include QLD-space(margin-bottom, 0.25unit);
            display: block;
            text-align: center;
        }

        svg{
            @include QLD-space(height, 1unit);
            @include QLD-space(width, 1unit);
            margin: 1px auto;
            color: var(--QLD-color-light-alt-button);

            @include QLD-media(md) {
                @include QLD-space(height, 1.25unit);
                @include QLD-space(width, 1.25unit);
                margin: 0 auto;
            }
        }

        &--open {
            color: var(--QLD-color-dark-text);
            background-color: transparent;
            
            &:active {
                background-color: var(--QLD-color-dark-alt-background);
            }
                    
        }
        
        // Close icon
        &--close {
            background-color: transparent ;
            @include QLD-media(md) {
                border-width: $QLD-border-width-default;
            }
            
            &:active {
                background-color: var(--QLD-color-dark-alt-background);
            }  
        }
    }

    &__toggle-search{

        &--open{
            .qld__main-nav__toggle-search-close-icon,
            .fa-times{
                display: none;
            }
        }

        &--close{
            .qld__main-nav__toggle-search-icon,
            .fa-search{
                display: none;
            }
        }

    }

    // Reset menus inside main-nav
    .qld__link-list {
        @include QLD-clearfix();
        margin: 0;
        padding: 0;
        // @include QLD-space(padding-bottom, 6.5unit);
        background-color: var(--QLD-color-light-background__shade);

        @include QLD-media(lg) {
            // display: flex;
            @include QLD-space(padding-bottom, 0unit);
        }
    }

    .qld__main-nav__cta-wrapper .qld__link-list{
        @include QLD-space(padding-bottom, 0unit);
    }

    &.qld__main-nav--dark {
        background-color: var(--QLD-color-dark-background);

        .qld__link-list{
            background-color: var(--QLD-color-dark-background__shade);

            @include QLD-media(lg) {
                background-color: var(--QLD-color-dark-background);
            }

        }
        .qld__main-nav__menu{
            .qld__main-nav__item-title::before{
                background-color: var(--QLD-color-dark-border);
            }
            .qld__main-nav__item{
                a{
                    @include QLD-underline('dark','noUnderline','default','noVisited');
                }
            }

            .qld__main-nav__item-link{
                color: var(--QLD-color-dark-link);
                border-color: var(--QLD-color-dark-designAccent);

                &:hover{
                    background-color: var(--QLD-color-dark-background);
                    border-color: var(--QLD-color-dark-alt-button__hover);

                    .qld__icon{
                        color: var(--QLD-color-dark-alt-button__hover);
                    }
                }
                
            }

            .qld__main-nav__item.active .qld__main-nav__item-link{
                color: var(--QLD-color-dark-link);

                @include QLD-media(lg) {
                    border-color: var(--QLD-color-dark-background);
                }
            }
            .qld__icon{
                color: var(--QLD-color-dark-alt-button);
            }
        } 
    }

    
}

// Lock the scroll on the whole page when the menu is open
.js .qld__main-nav__scroll--locked {
    overflow: hidden;
    
    // Remove the scroll lock on a desktop computer
    @include QLD-media($QLD-main-nav-breakpoint) {
        overflow: visible;
    }
}

// Vertical Nav Option
.vertical-nav {
    .qld__main-nav {
        @include QLD-media(lg) {
            display: none;
        }
    }
}

.qld__main-nav__menu-inner {

    div.qld__mega-nav_mobile-filler-120 {
        display: block;
        height: 0;
        padding-bottom: 120px;

        @include QLD-media(lg) {
            display: none;
        }
    }

    div.qld__mega-nav_mobile-filler-60 {
        display: block;
        height: 0;
        padding-bottom: 60px;

        @include QLD-media(lg) {
            display: none;
        }
    }

}

// Print styles
@media print {
	.qld__main-nav {
        // background: transparent !important;
        // border-bottom: none !important;
        display: none;

        a {
            color: #000 !important;

            &:hover {
                background: transparent !important;
            }
        }

        .active a {
            border-color: #fff !important;
            background-color: transparent !important;
        }

        .qld__link-list > li {
            border-color: #000 !important;
            
            &:last-of-type {
                border-bottom: 1px solid !important;
            }
        }

        @include QLD-media( $QLD-main-nav-breakpoint ) {
            @include QLD-space( border-bottom, 0.5unit solid #000 !important );

            .qld__link-list > li:last-of-type {
                border-bottom: none !important;
            }
        }
	}

	.qld__main-nav__toggle {
	    display: none !important;
	}

	.qld__main-nav__menu {
        background: transparent !important;
        position: static !important;
        display: block !important;
        width: auto !important;

        .qld__main-nav__menu-inner {
            padding: 0 !important;
        }
	}
}