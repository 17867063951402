.qld__body--video {
    .row {
        background: $QLD-color-dark-background;
        background-color: $QLD-color-dark-background;

        color: $QLD-color-dark-text;

        padding: 30px 30px 40px;

        @media screen and (min-width: 699px) {
            border-radius: 60px;
        }

        @media screen and (min-width: 992px) {
            padding: 60px;
            margin: 0 -60px;

            display: flex;
            align-items: start;
        }
    }

    h2 {
        color: $QLD-color-dark-text;
    }

    .video-wrapper {
        margin-bottom: 0;
    }

    .qld__accordion {
    }

    .qld__accordion-group {
        width: 100% !important;
        min-width: 100% !important;
        margin-top: 0 !important;
        border-radius: 0 0 5px 5px !important;
        overflow: hidden !important;
    }

    .video-wrapper.video-wrapper--active {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }

    .video-wrapper iframe,
    .video-wrapper object,
    .video-wrapper embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-wrapper {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        line-height: 0;
    }

    .video-wrapper iframe {
        border: none;
        width: 100%;
    }

    .video-placeholder {
        cursor: pointer;
    }

    h2 {
        margin-top: 30px;
    }

    h4 {
        color: #fff;
    }
}
