/*
 * Mobile layout
 */

body {
    z-index: 1;
}

.qld__table__attributes {
    font-size: 0.9em;

    .qld__model_field {
        width: 100%;
    }

    textarea {
        height: 100px;
    }
}

/*   New Classes that apply the form sizes variables
  ----------------------------------------------------------------------------------------------------------
      
New classes to dictate field size, this applied variables that affec thte max-width of a for inpout field

---------------------------------------------------------------------------------------------------------- */

input.qld__field-width--2char,
select.qld__field-width--2char {
    max-width: $QLD-fixed-width__char-2;
}

input.qld__field-width--3char,
select.qld__field-width--3char {
    max-width: $QLD-fixed-width__char-3;
}

input.qld__field-width--4char,
select.qld__field-width--4char {
    max-width: $QLD-fixed-width__char-4;
}

input.qld__field-width--5char,
select.qld__field-width--5char {
    max-width: $QLD-fixed-width__char-5;
}

input.qld__field-width--10char,
select.qld__field-width--10char {
    max-width: $QLD-fixed-width__char-10;
}

input.qld__field-width--20char,
select.qld__field-width--20char {
    max-width: $QLD-fixed-width__char-20;
}

input.qld__field-width--xs,
select.qld__field-width--xs {
    max-width: $QLD-form-input__width-xs;
}

input.qld__field-width--sm,
select.qld__field-width--sm {
    max-width: $QLD-form-input__width-sm;
}

input.qld__field-width--md,
select.qld__field-width--md {
    max-width: $QLD-form-input__width-md;
}

input.qld__field-width--lg,
select.qld__field-width--lg
{
max-width: $QLD-form-input__width-lg;
}

input.qld__field-width--xl,
select.qld__field-width--xl
{
max-width: $QLD-form-input__width-xl;
}

/*  Default for all fluid styls is full - these are adjusts at larger break points, they keeps the fluid width style fomrs looking good on mobile  */

input.qld__field-width--full,
select.qld__field-width--full {
    max-width: $QLD-fluid-width__full;
}

input.qld__field-width--3-quarters,
select.qld__field-width--3-quarters {
    max-width: $QLD-fluid-width__full;
}

input.qld__field-width--half,
select.qld__field-width--half {
    max-width: $QLD-fluid-width__full;
}

input.qld__field-width--1-quarter,
select.qld__field-width--1-quarter {
    max-width: $QLD-fluid-width__full;
}

@include QLD-media (md) {
    input.qld__field-width--1-quarter,
    select.qld__field-width--1-quarter {
        max-width:$QLD-fluid-width__1-quarter;
    }

    input.qld__field-width--half,
    select.qld__field-width--half {
        max-width:$QLD-fluid-width__half;
    }

    input.qld__field-width--3-quarters,
    select.qld__field-width--3-quarters {
        max-width:$QLD-fluid-width__3-quarters;
    }
}

/*
 * Tablet layout
 */

@media screen and (min-width: md) {
    body {
        z-index: 2;
    }
}


/*
 * Desktop layout
 */

@media screen and (min-width: lg) {
    body {
        z-index: 3;
    }
}