//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// {{component_name}}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__tab {

    &-intro{
        @include QLD-space(padding-bottom, 1.5unit);
        @include QLD-media(lg) {
            @include QLD-space(padding-bottom, 2unit);
        }
    }
	
    &s {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 4px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-button {
        padding: 8px 20px 0px 20px;
        min-width: fit-content;
        border: none;
        background: var(--QLD-color-light-background__shade);
        font-size: 1rem;
        color: var(--QLD-color-light-link);
        cursor: pointer;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        gap: 8px;
        align-items: baseline;
        text-align: left;

        span {
            display: inline-block;

            i {
                margin-right: 8px;
                color: var(--QLD-color-dark-designAccent);
            }
        }

        &::after {
            content: "";
            width: 100%;
            height: 4px;
            position: relative;
            bottom: 0px;
            background-color: transparent;
            border-radius: 4px 4px 0px 0px;
        }

        &:not(.active):hover {
            background: var(--QLD-color-light-button__hover);
            color: var(--QLD-color-light-button__text);
            text-decoration: underline;
            text-underline-offset: var(--QLD-underline__offset);
            text-decoration-thickness: var(--QLD-underline__thickness-thick);
            text-decoration-color: var(--QLD-color-light-button__text);
            outline: none;

            span i {
                color: var(--QLD-color-light-button__text);
                text-decoration: none;
                display: inline-block;
            }
        }

        &[tabindex="0"]:focus {
            outline: none;
            border-color: var(--QLD-color-light-focus);
        }

        &[tabindex="-1"]:focus,
        &:focus-visible {
            outline: 3px solid var(--QLD-color-light-focus);
            outline-offset: -3px;
        }

        &.active {
            color: var(--QLD-color-light-heading);
            background-color: var(--QLD-color-light-background);
            height: 48px;
            border-left: 1px solid var(--QLD-color-light-alt-border);
            border-right: 1px solid var(--QLD-color-light-alt-border);
            border-top: 1px solid var(--QLD-color-light-alt-border);
            font-weight: 600;
            z-index: 1;
            text-decoration: none;

            span {
                i {
                    color: var(--QLD-color-light-heading);
                }
            }

            &::after {
                background-color: var(--QLD-color-dark-designAccent);
            }

            &:focus::after{
                background-color: var(--QLD-color-light-focus);
                transition: background-color 0.2s ease-in-out;
            }
        }
    }

    &-content {
        padding: 24px;
        border: 1px solid var(--QLD-color-light-alt-border);
        margin-top: -1px;
        display: none;
        position: relative;
        pointer-events: none;

        &.active {
            display: block;
            pointer-events: auto;
        }

        &.focused{
            border-color: var(--QLD-color-light-focus);
        }

    }

    &-nav__item-scroll {
        position: absolute;
        color: var(--QLD-color-light-alt-button);
        z-index: 2;
        height: 47px;
        width: 48px;
        border: none;

        i {
            min-height: 32px;
            min-width: 32px;
            background-color: var(--QLD-color-light-background);
            align-self: center;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            border: none;
            border-radius: 50%;
            position: relative;
            font-size: 0.625rem;
            display: inline-block;
            line-height: 32px;
            transition: all 0.2s ease-in-out;

            &:hover {
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 1px rgba(0, 0, 0, 0.05);
                transform: scale(1.125);
            }
        }

        &:hover {
            position: absolute;
            color: var(--QLD-color-light-alt-button__hover);
            z-index: 2;
            height: 47px;
            width: 48px;
            border: none;
        }

        &:focus {
            i {
                outline: 3px solid var(--QLD-color-light-focus);
                outline-offset: 2px;
            }
        }

        &.tab-overflow-nav-button-left {
            display: none;
            left: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--QLD-color-light-background));
    
        }
    
        &.tab-overflow-nav-button-right {
            display: none;
            right: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--QLD-color-light-background));
        }
    }

    &-container {
        &__fixed {
            width: 100%;
            position: relative;
        }

        &.qld__tab-container--alt {
            .qld__tab-button {
                background: var(--QLD-color-light-alt-background__shade);

                &:not(.active):hover {
                    background: var(--QLD-color-light-button__hover);
                }
                &.active {
                    background-color: var(--QLD-color-light-alt-background);
                }
            }

            .qld__tab-nav__item-scroll {
                &.tab-overflow-nav-button-left {
                    display: none;
                    background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--QLD-color-light-alt-background));
                }

                &.tab-overflow-nav-button-right {
                    display: none;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--QLD-color-light-alt-background));
                }
            }
        }

        &.qld__tab-container--dark {
            .qld__tab-button {
                color: var(--QLD-color-dark-link);
                background: var(--QLD-color-dark-background__shade);

                span i {
                    color: var(--QLD-color-dark-alt-button);
                }

                &.active {
                    background-color: var(--QLD-color-dark-background);
                    color: var(--QLD-color-dark-heading);
                    height: 48px;
                    border-left: 1px solid var(--QLD-color-dark-alt-border);
                    border-right: 1px solid var(--QLD-color-dark-alt-border);
                    border-top: 1px solid var(--QLD-color-dark-alt-border);
                    font-weight: 600;
                    z-index: 1;
                    text-decoration: none;
                    outline: none;

                    &::after {
                        background-color: var(--QLD-color-dark-designAccent);
                    }

                    span i {
                        color: var(--QLD-color-dark-heading);
                        text-decoration: none;
                        display: inline-block;
                    }
                }

                &:not(.active):hover {
                    color: var(--QLD-color-dark-button__text);
                    background: var(--QLD-color-dark-button__hover);
                    text-decoration: underline;
                    text-underline-offset: var(--QLD-underline__offset);
                    text-decoration-thickness: var(--QLD-underline__thickness-thick);
                    text-decoration-color: var(--QLD-color-dark-button__text);

                    span i {
                        color: var(--QLD-color-dark-button__text);
                        text-decoration: none;
                        display: inline-block;
                    }
                }

                &:focus {
                    outline: 3px solid var(--QLD-color-dark-focus);
                    outline-offset: -3px;
                }

                &[tabindex="0"]:focus {
                    outline: none;
                }

                &[tabindex="-1"]:focus,
                &:focus-visible {
                    outline: 3px solid var(--QLD-color-dark-focus);
                    outline-offset: -3px;
                }
            }
            
            .qld__tab-nav__item-scroll {
                &.tab-overflow-nav-button-left {
                    display: none;
                    background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--QLD-color-dark-background));
                }

                &.tab-overflow-nav-button-right {
                    display: none;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--QLD-color-dark-background));
                }
            }

            .qld__tab-content {
                border: 1px solid var(--QLD-color-dark-alt-border);

                &.focused {
                    border-color: var(--QLD-color-dark-focus);
                }
            }
        }

        &.qld__tab-container--dark-alt {
            .qld__tab-button {
                color: var(--QLD-color-dark-link);
                background: var(--QLD-color-dark-alt-background__shade);

                span i {
                    color: var(--QLD-color-dark-alt-button);
                }

                &.active {
                    background-color: var(--QLD-color-dark-alt-background);
                    color: var(--QLD-color-dark-heading);
                    height: 48px;
                    border-left: 1px solid var(--QLD-color-dark-alt-border);
                    border-right: 1px solid var(--QLD-color-dark-alt-border);
                    border-top: 1px solid var(--QLD-color-dark-alt-border);
                    font-weight: 600;
                    z-index: 1;
                    text-decoration: none;
                    outline: none;
                    
                    &::after {
                        background-color: var(--QLD-color-dark-designAccent);
                    }

                    span i {
                        color: var(--QLD-color-dark-heading);
                        text-decoration: none;
                        display: inline-block;
                    }
                }

                &:not(.active) {
                    // background: var(--QLD-color-light-alt-background__shade);
                    // color: var(--QLD-color-light-link);
                }

                &:not(.active):hover {
                    color: var(--QLD-color-dark-button__text);
                    background: var(--QLD-color-dark-button__hover);
                    text-decoration: underline;
                    text-underline-offset: var(--QLD-underline__offset);
                    text-decoration-thickness: var(--QLD-underline__thickness-thick);
                    text-decoration-color: var(--QLD-color-dark-button__text);

                    span i {
                        color: var(--QLD-color-dark-button__text);
                        text-decoration: none;
                        display: inline-block;
                    }
                }

                &:focus {
                    outline: 3px solid var(--QLD-color-dark-focus);
                    outline-offset: -3px;

                    &:after {
                        background-color: var(--QLD-color-dark-focus);
                    }
                }

                &[tabindex="0"]:focus {
                    outline: none;
                }

                &[tabindex="-1"]:focus,
                &:focus-visible {
                    outline: 3px solid var(--QLD-color-dark-focus);
                    outline-offset: -3px;
                }
            }

            .qld__tab-nav__item-scroll {
                &.tab-overflow-nav-button-left {
                    display: none;
                    background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--QLD-color-dark-alt-background));
                }

                &.tab-overflow-nav-button-right {
                    display: none;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--QLD-color-dark-alt-background));
                }
            }

            .qld__tab-content {
                border: 1px solid var(--QLD-color-dark-alt-border);

                &.focused {
                    border-color: var(--QLD-color-dark-focus);
                }
            }
        }
    }
}

#content .qld__tab-container{
    .qld__tab-content{
        
        > section.qld__body:first-of-type{
            padding-top: 0;
        }
        > section.qld__body:last-of-type{
            padding-bottom: 0;
        }

        > section.qld__body > .container-fluid {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

#content .qld__tab-section .qld__tab-container{
    .qld__tab-content{   
        padding-left: 0px;
        padding-right: 0px;
    }
}
    
    
    


*:not(.row) + .qld__tab-container {
    margin-top: 32px;
}

.qld__tab-section {
    border-top: 1px solid var(--QLD-color-light-alt-border);
    margin-top: 48px;
    box-shadow: 0 -49px 0 0 var(--QLD-color-light-background);

    .qld__tab-button[tabindex="0"]:focus{
        outline: none;
        border-color:var(--QLD-color-light-alt-border);
    }

    .qld__tab-container {
        &__fixed {
            width: 100%;
            position: relative;

            .qld__tabs {
                margin-top: -88px;

                @include QLD-media(lg) {
                    margin-top: -112px;
                }
            }
        }
    }

    .qld__tab-content {
        padding-top: 64px;
    }

    &.qld__body--alt {
        .qld__tab-content {
            border-bottom: none;
            border-left: none;
            border-right: none;
        }
    }
    
    &.qld__body--dark,
    &.qld__body--dark-alt {
        border-top: 1px solid var(--QLD-color-dark-alt-border);

        .qld__tab-content {
            border-top: 1px solid var(--QLD-color-dark-alt-border);
            border-bottom: none;
            border-left: none;
            border-right: none;
        }
    }
}

.qld__banner.qld__banner--alt+#content > section:first-child,
.qld__banner.qld__banner--alt+#content > data:first-child + section {

        &.qld__tab-section{
            box-shadow: 0 -49px 0 0 var(--QLD-color-light-alt-background);
        }
    
}
.qld__banner.qld__banner--dark+#content > section:first-child,
.qld__banner.qld__banner--dark+#content > data:first-child + section {

        &.qld__tab-section{
            box-shadow: 0 -49px 0 0 var(--QLD-color-dark-background);
        }
    
}
.qld__banner.qld__banner--dark-alt+#content > section:first-child,
.qld__banner.qld__banner--dark-alt+#content > data:first-child + section {

        &.qld__tab-section{
            box-shadow: 0 -49px 0 0 var(--QLD-color-dark-alt-background);
        }
    
}

.qld__body--alt+.qld__tab-section,
.qld__body--alt+data+.qld__tab-section {
    box-shadow: 0 -49px 0 0 var(--QLD-color-light-alt-background);
}

.qld__body--dark+.qld__tab-section,
.qld__body--dark+data+.qld__tab-section {
    box-shadow: 0 -49px 0 0 var(--QLD-color-dark-background);

    .qld__tab-container__fixed .qld__tabs {
        @include QLD-media(lg) {
            margin-top: -112px;
        }
    }
}

.qld__body--dark-alt+.qld__tab-section,
.qld__body--dark-alt+data+.qld__tab-section {
    box-shadow: 0 -49px 0 0 var(--QLD-color-dark-alt-background);

    .qld__tab-container__fixed .qld__tabs {
        @include QLD-media(lg) {
            margin-top: -112px;
        }
    }
}

.qld__body--full-width {
    .qld__tab {
        &-content {
            border-bottom: none;
            border-left: none;
            border-right: none;
            padding-left: 0;
        }

        &-container {
            // &__fixed {

                .qld__tab-button {
                    span i {
                        color: var(--QLD-color-dark-alt-button);
                    }

                    &:not(.active) {
                        color: var(--QLD-color-dark-link);
                        background: var(--QLD-color-dark-background__shade);

                        &:hover {
                            color: var(--QLD-color-dark-button__text);
                            background: var(--QLD-color-dark-button__hover);
                            text-decoration-color: var(--QLD-color-dark-button__text);

                            span i {
                                color: var(--QLD-color-dark-button__text);
                            }
                        }
                    }

                    &.active {
                        span i {
                            color: var(--QLD-color-light-heading);
                        }
                    }
                }

                &--alt {
                    .qld__tab-button {
                        &:not(.active) {
                            background: var(--QLD-color-light-background__shade);
                            color: var(--QLD-color-light-link);

                            span i {
                                color: var(--QLD-color-light-alt-button);
                            }

                            &:hover {
                                background: var(--QLD-color-light-button__hover);
                                color: var(--QLD-color-light-button__text);
                                text-decoration-color: var(--QLD-color-light-button__text);

                                span i {
                                    color: var(--QLD-color-dark-heading);
                                }
                            }
                        }

                        &.active {
                            span i {
                                color: var(--QLD-color-light-heading);
                            }
                        }
                    }
                }

                &--dark {
                    .qld__tab-button {
                        &:not(.active) {
                            background: var(--QLD-color-light-background__shade);
                            color: var(--QLD-color-light-link);
                        }
                    }
                }

                &--dark,
                &--dark-alt {
                    .qld__tab-button {
                        span i {
                            color: var(--QLD-color-light-alt-button);
                        }

                        &:not(.active) {
                            background-color: var(--QLD-color-light-alt-background__shade);
                            color: var(--QLD-color-light-link);
                        }
        
                        &:not(.active):hover {
                            background: var(--QLD-color-light-button__hover);
                            color: var(--QLD-color-light-button__text);
                            text-decoration-color: var(--QLD-color-light-button__text);

                            span i {
                                color: var(--QLD-color-dark-heading);
                            }
                        }

                        &.active {
                            span i {
                                color: var(--QLD-color-dark-heading);
                            }
                        }
                    }
                }
            // }
        }
    }
}