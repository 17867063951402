.qld__header__pre-header {
    .qld__header__pre-header-brand-image {
        display: block;
        order: 1;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        align-self: center;
    }
    .qld__header__pre-header-url {
        order: 2;
    }
}

.qld__header__site-name,
.qld__header__main-nav-controls {
    display: none !important;
}

.qld__header .qld__header__main .qld__header__brand-image{
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
    display: block!important;

    &:hover{
        cursor: auto;
        filter: none!important;
    }

}