.qld__body.qld__body--promo {
    
    // @media screen and (min-width: 1300px) {
    //     max-width: 1200px;
    //     margin: 0 auto;
    // }

    // @media screen and (min-width: 1400px) {
    //     max-width: 1300px;
    //     margin: 0 auto;
    // }
    // @media screen and (min-width: 1500px) {
    //     max-width: 1400px;
    //     margin: 0 auto;
    // }

    .qld__body--promo__image {
        width: 100%;
        height: 80%;
        background-size: cover;
        background-position: center center;
        min-height: 300px;
        border-radius: 48px 48px 0 0;

        @media screen and (min-width: 992px) {
            border-radius: 48px 0 0 48px;
        }
    }

    .qld__body--promo__content {
        &-inner {
            padding: 32px;
            border-radius: 0 0 48px 48px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }

        @media screen and (min-width: 992px) {
            .qld__body--promo__content-inner {
                border-radius: 0 48px 48px 48px;
                padding-left: 32px;
            }
        }
    }

    .qld__link-list {
        button.qld__btn {
            padding: 10px 22px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }
        button.qld__btn.qld__btn--alt {
            background: #f9a01b;
            border: solid 2px #f9a01b;
            color: #121212;
            text-decoration-color: #121212;

            &:hover {
                text-decoration-color: #121212;
            }
        }
    }

    .row {
        @media screen and (min-width: 992px) {
            & > div:first-child {
                padding-right: 0;
            }

            & > div:last-child {
                padding-left: 0;
            }
        }
        @media screen and (min-width: 1500px) {
            margin: 0 -140px;

            & > div:first-child {
                padding-left: 75px;
                padding-right: 0;
            }

            & > div:last-child {
                padding-left: 0;
                padding-right: 75px;
            }
        }
    }
}
