.qld__header__pre-header,
.qld__header__main,
.qld__body,
.qld__footer,
.qld__banner,
.qld__main-nav{
    
    .qld__link,
    a.qld__link{
        color: var(--QLD-color-light-link);
        @include QLD-underline('light');
        max-width: $QLD-font-maxwidth;

        i{
			color: var(--QLD-color-light-button__text);
		}
	
		&:hover{
			border-color: var(--QLD-color-light-button__hover);
		}
	
		&:focus {
			@include QLD-focus();
		}
	
		&:disabled,
		&[disabled]{
			cursor: not-allowed;
			color: var(--QLD-color-light-text__muted);
	
			i{
				color: var(--QLD-color-light-text__muted);
			}
		}
    }

    &.qld__body--dark,
	&.qld__body--dark-alt,
	&.qld__banner--dark,
	&.qld__banner--dark-alt,
	&.qld__header__main--dark,
	&.qld__header__main--dark-alt,
	&.qld__footer--dark,
	&.qld__footer--dark-alt,
	.qld__code-header,
	.qld__code.singleline{

        .qld__link,
        a.qld__link{
            color: var(--QLD-color-dark-link);
            @include QLD-underline('dark');

            i{
                color: var(--QLD-color-dark-button__text);
            }
        
            &:hover{
                border-color: var(--QLD-color-dark-button__hover);
            }
        
            &:focus {
                @include QLD-focus('dark');
            }
        
            &:disabled,
            &[disabled]{
                color: var(--QLD-color-dark-text__muted);
        
                i{
                    color: var(--QLD-color-dark-text__muted);
                }
            }
        }
    }
}

