//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Page alerts
//--------------------------------------------------------------------------------------------------------------------------------------------------------------


.qld__page-alerts {
	@include QLD-space( padding, 1unit );
	@include QLD-fontgrid( sm );
	position: relative;
	border: solid $QLD-border-width-default $QLD-color-info;
	@include QLD-space( border-left-width, 3unit );
	border-radius: $QLD-border-radius-xs;
	word-wrap: break-word;
	background-color: var(--QLD-color-light-background);
	max-width: 48rem;
	
	&::before{
		content: ' ';
		@include QLD-space( width, 1.25unit );
		@include QLD-space( height, 1.25unit );
		display: inline-block;
		vertical-align: text-top;
		mask-image: QLD-svguri($QLD-icon-info);
		mask-repeat: no-repeat;
		mask-position: center;
		background-color: $QLD-color-system-white;
		position: absolute;
		@include QLD-space( left, -1.5unit );
		top: 50%;
		transform: translate(-50%, -50%);
	}

	& &--heading.qld__display-lg {
		color: var(--QLD-color-light-heading);
		@include QLD-space(margin-bottom, 1unit);
	}

	& a{
		color: var(--QLD-color-light-link);
		@include QLD-underline('light');
	}

	/**
	* Page alert success
	*/
	&.qld__page-alerts--success {
		border-color: $QLD-color-success;

		&::before{
			background-color: $QLD-color-system-darkest;
			mask-image: QLD-svguri($QLD-icon-success);
		}
	}

	/**
	* Page alert warning.
	*/
	&.qld__page-alerts--warning {
		border-color: $QLD-color-warning;

		&::before{
			background-color: $QLD-color-system-darkest;
			mask-image: QLD-svguri($QLD-icon-warning);
		}
	}

	/**
	* Page alert error.
	*/
	&.qld__page-alerts--error {
		border-color: $QLD-color-error;

		&::before{
			background-color: $QLD-color-system-white;
			mask-image: QLD-svguri($QLD-icon-error);
		}
	}

	// // dark and dark alt
	.qld__body--dark &,
	.qld__body--dark-alt &{
		color: var(--QLD-color-light-text);
		
		h3{
			color: var(--QLD-color-light-heading);
		}

		& a{
			color: var(--QLD-color-light-link);
			@include QLD-underline('light');
			&:hover{
				color: var(--QLD-color-light-link);
			}
		}
	}
}


.qld__body #content > * + .qld__page-alerts  {
	@include QLD-space( margin-top, 2unit );
}


/**
 * Screen-reader only class for interlinking error messages and corresponding form elements.
 */
.qld__page-alerts__sronly {
	@include QLD-sronly;
}


// Print styles
@media print {
	.qld__page-alerts {
		border-color: #000 !important;
		background-color: #fff !important;
		border-left: 2px solid #000 !important;
		padding-top: 3em !important;

		&:after {
			background: none !important;
			content: 'info' !important;
			top: 1em !important;
			left: 0 !important;
			font-size: 12px !important;
			border-right: 1px solid #000 !important;
			border-bottom: 1px solid #000 !important;
			padding: 0.5em !important;
			width: auto !important;
		}
	}

	.qld__page-alerts--success:after {
		content: 'success' !important;
	}

	.qld__page-alerts--warning:after {
		content: 'warning' !important;
	}

	.qld__page-alerts--error:after {
		content: 'error' !important;
	}
}
