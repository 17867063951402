//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Horizontal rule
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

hr.qld__horizontal-rule {
	background-color:var(--QLD-color-light-border);
	border: none;

	.qld__card .qld__card__footer &{
		background-color:var(--QLD-color-light-border);
	}

	.qld__body--alt &,
	.qld__card--alt .qld__card__footer &{
		background-color: var(--QLD-color-light-alt-border);
	}
	.qld__body--dark &,
	.qld__card--dark .qld__card__footer &,
	.qld__main-nav--dark &{
		background-color: var(--QLD-color-dark-border);
	}
	.qld__body--dark-alt &,
	.qld__card--dark-alt .qld__card__footer &{
		background-color: var(--QLD-color-dark-alt-border); 
	}

	&.qld__horizontal-rule--md{
		height: 2px;
		@include QLD-space(margin, 1.438unit 0 1.438unit 0);
	}

	&.qld__horizontal-rule--lg{
		height: 2px;
		@include QLD-space(margin, 1.938unit 0 1.938unit 0);
	}

}
