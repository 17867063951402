.qld__banner--dark {
    border-top: solid 8px #f9a01b;

    .qld__banner__content .qld__banner__heading--dark {
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .qld__btn {
        display: inline-block !important;
        margin-top: 40px;
    }

    .qld__banner__content{
        align-items: start;
    }

}
