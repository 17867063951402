$QLD-color-light-alt-border: #E0E0E0!important;
$QLD-color-accent: #F9A01B!important;

.qld__card-list.qld__card-default {
    .qld__card{
        align-items: start;
        border: 1px solid $QLD-color-light-alt-border;
    }

    .qld__card .qld__card--clickable__link{
        text-decoration: none;
    }

    .qld__card__icon{
        align-self: flex-start;
        i{
            color: $QLD-color-accent;
        }
    }
}