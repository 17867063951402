.qld__body.qld__body--light-shade{
    
    background: #f5f5f5;
    padding-top: 4rem!important;
    margin-bottom: 4rem!important;
    
    p{
        max-width: 100%;
    }

    @media screen and (min-width: 1300px) {
        padding:0;
        background: #f5f5f5;

        .row{
            padding: 4rem;
            margin: 0 -4rem;

            div:first-child{
                padding-left: 0;
            }
            div:last-child{
                padding-right: 0;
            }
        }
    }
}