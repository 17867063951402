body{
    overflow-x: hidden;
}

.qld__body{
    .row > div{
        margin-bottom: 20px!important;
    }
}

.qld__body.qld__grid{
    h4{
        color: #000;
    }
}
.qld__body.qld__grid.qld__body--video {
    h4 {
        color: #FFFFFF;
    }
}
//.video-wrapper {
//    position: relative;
//    padding-bottom: 56.25%; /* 16:9 aspect ratio */
//    height: 0;
//    overflow: hidden;
//}
//
//.video-container {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//}

.video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
