.qld__footer{

    .qld__footer__column:has(.qld__footer__social){
        display: none;
    }

    .qld__footer__cta-content i{
        color: #f9a01b;
        margin-right: 5px;
        width: 15px;
        height: 15px;
        font-size: 15px;;
    }

    .qld__btn.qld__btn--secondary{
        min-width: 200px;
    }
}
