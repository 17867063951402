//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// {{qld__data-table}}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------
$arrowUpDow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M171.3 36.7c-6.2-6.2-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L144 86.6V464c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6l68.7 68.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-96-96zm352 342.6c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L432 425.4V48c0-8.8-7.2-16-16-16s-16 7.2-16 16V425.4l-68.7-68.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l96 96c6.2 6.2 16.4 6.2 22.6 0l96-96z" fill="rgb(0, 135, 51)" /></svg>';
$arrowUp: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M203.3 36.7c-6.2-6.2-16.4-6.2-22.6 0l-176 176c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L176 86.6V464c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6L356.7 235.3c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-176-176z" fill="rgb(0, 135, 51)" /></svg>';
$arrowDown: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M180.7 475.3c6.2 6.2 16.4 6.2 22.6 0l176-176c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 425.4 208 48c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 377.4L27.3 276.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l176 176z" fill="rgb(0, 135, 51)" /></svg>';
$chevronDown: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 417.9l17-17L465 209l17-17L448 158.1l-17 17-175 175L81 175l-17-17L30.1 192l17 17L239 401l17 17z" fill="rgb(0, 135, 51)" /></svg>';

$arrowUpDowDark: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M171.3 36.7c-6.2-6.2-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L144 86.6V464c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6l68.7 68.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-96-96zm352 342.6c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L432 425.4V48c0-8.8-7.2-16-16-16s-16 7.2-16 16V425.4l-68.7-68.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l96 96c6.2 6.2 16.4 6.2 22.6 0l96-96z" fill="rgb(239, 215, 0)" /></svg>';
$arrowUpDark: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M203.3 36.7c-6.2-6.2-16.4-6.2-22.6 0l-176 176c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L176 86.6V464c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6L356.7 235.3c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-176-176z" fill="rgb(239, 215, 0)" /></svg>';
$arrowDownDark: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M180.7 475.3c6.2 6.2 16.4 6.2 22.6 0l176-176c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 425.4 208 48c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 377.4L27.3 276.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l176 176z" fill="rgb(239, 215, 0)" /></svg>';
$chevronDownDark: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 417.9l17-17L465 209l17-17L448 158.1l-17 17-175 175L81 175l-17-17L30.1 192l17 17L239 401l17 17z" fill="rgb(239, 215, 0)" /></svg>';

table.qld__data-table, table.dataTable { // the table needs to be used for this as an exception to strengthen the scss for .sorting
    thead {
        tr {
            th {
                &,
                &.sorting::before,
                &.sorting::after, 
                &.sorting.sorting_desc::before,
                &.sorting.sorting_desc::after, 
                &.sorting.sorting_asc::before,
                &.sorting.sorting_asc::after {
                    content: none;
                    font-size: 1rem;
                }
            }
        }
    }

    tbody {
        th, td {
            padding: 0.75rem;
        }
    }

    tfoot {
        th {
            font-size: 1rem;
        }
    }

    &.no-footer {
        border-bottom: none;
    }
}

.qld__data-table { //first level styles except for border and border radius need to be removed after merging with tables.

    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    &>tbody>tr>td,
    &>tfoot>th {
        padding: 0.75rem;
    }
    
    a {
        cursor: pointer;
    }

    //start* this is a datatables bug that even if you add your own search box, they keep their searchbox on the page. so, i needed to remove it manually here until they fix it and we update to the fixed version fo the datatables. 
    #qld__header__search-query {
        display: none;
    }

    .dataTables_filter {

        max-width: 360px;
        flex: 40%;

        label {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            input {
                height: 3rem;
            }

            input:not(#qld__data-table__search-field) {
                display: none;
            }
        }
        
        @include QLD-media(md) {
            max-width: 360px;
            flex: 40%;
    
            label {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                input {
                    height: 3rem;
                    flex: 1;
                    margin-left: 12px;
                }
    
                input:not(#qld__data-table__search-field) {
                    display: none;
                }
            }
        }
    }

    //end*

    & .bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 12px;
        align-items: center;
        gap: 16px;
        


        @include QLD-media(sm) {
            flex-direction: row;
        }        

        .dataTables_length {
            display: flex;
            justify-content: flex-start;

            label {
                display: flex;
                justify-content: flex-start;
            }

            @include QLD-media(sm) {
                display: block;
                max-width: 40%;   
                label {
                    display: block;
                }
            }
        }

        & .dataTables_paginate > .qld__search-pagination__list {
    
            display: flex;
            justify-content: flex-start;
            & li.disabled {
                display: none;
            }
    
            @include QLD-media(sm) {
                justify-content: flex-end;
    
            }
    
        }
    }

    & &__header { //header can go after merging with tables.
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: var(--QLD-color-dark-system-underline);
        box-shadow: inset 0 -1px 0 var(--QLD-color-light-border);

        .qld__caption{
            font-style: normal;
            color: var(--QLD-color-light-text__muted);
            font-size: 14px;
            font-size: 0.87rem;
            line-height: 1.4;
            font-weight: 400;
            margin-top: 0;
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }


    .qld__data-table__head,
    thead {
        text-align: left;
        display: table-header-group;
        tr {

            &:nth-child(2) {
                background-color: var(--QLD-color-light-background__shade);
            }

            th {
    
                background-image: url("data:image/svg+xml;charset=utf-8,#{$arrowUpDow}");
                background-repeat: no-repeat;
                background-position: right 11px center;
                background-size: 1rem auto;
                box-sizing: border-box;
                color: var(--QLD-color-light-link);

                &:hover,
                &:focus {
                    text-decoration: underline;
                    box-shadow: inset 0 -2px 0 var(--QLD-color-light-button__hover);
                }
    
                &:active {
                    box-shadow: inset 0 -2px 0 var(--QLD-color-light-designAccent);
                }
    
                color: var(--QLD-color-light-hover-underline);
                padding: 1.25rem 0.75rem 1.25rem 0.75rem;
            
                box-shadow: inset 0 0 -$QLD-border-width-default 0 var(--QLD-color-light-alt-border);
    
                font-weight: bold;
                @include QLD-fontgrid(md, heading);
    
                &.qld__data-table__cell--numeric {
                    text-align: right;
                    font-variant: tabular-nums;
                }
    
                &.qld__data-table__cell--middle {
                    text-align: center;
                }
    
                &.qld__data-table__header--width-10 {
                    width: 10%;
                }
    
                &.qld__data-table__header--width-15 {
                    width: 15%;
                }
    
                &.qld__data-table__header--width-20 {
                    width: 20%;
                }
    
                &.qld__data-table__header--width-25 {
                    width: 25%;
                }
    
                &.qld__data-table__header--width-33 {
                    width: 33%;
                }
    
                &.qld__data-table__header--width-40 {
                    width: 40%;
                }
    
                &.qld__data-table__header--width-50 {
                    width: 50%;
                }
    
                &.qld__data-table__header--width-75 {
                    width: 75%;
                }
    
                &.sorting_asc,
                &.sorting_desc { //needs to stayl
                    background-color: var(--QLD-color-light-background__shade);
                    box-shadow: inset 0 -2px 0 var(--QLD-color-light-designAccent);
                    text-decoration: underline;
                }
    
                &.sorting_desc { //needs to stayl
                    background-image: url("data:image/svg+xml;charset=utf-8,#{$arrowDown}");
                }
    
                &.sorting_asc { //needs to stayl
                    background-image: url("data:image/svg+xml;charset=utf-8,#{$arrowUp}");
                }
            }
        }
    }

    @for $i from 1 through 10 { //needs to stay.
        &.qld__data-table__col-#{$i}-num {
            tr {

                td:nth-child(#{$i}),
                th:nth-child(#{$i}):not([colspan]) {
                    text-align: right;
                    font-variant: tabular-nums;
                }
            }
        }
    }

    .qld__data-table__cell,
    td {
        @include QLD-standard-space(padding, 3);
        padding: 12px 12px 12px 12px;
        box-shadow: inset 0 0 -$QLD-border-width-thin 0 var(--QLD-color-light-border);

        &.qld__data-table__cell--numeric {
            text-align: right;
            font-variant: tabular-nums;
        }

        &.qld__data-table__cell--middle {
            text-align: center;
        }

        &.sorting_1 { //needs to stayl
            background-color: var(--QLD-color-light-background__shade);
        }
    }

    .qld__data-table__row,
    tr {
        display: table-row;
    }

    tfoot {
        background-color: var(--QLD-color-light-background);
        box-shadow: 0 1px 0 var(--QLD-color-light-border);

        &.qld__data-table-footer, tr { //needs to stayl
            th {
                box-shadow: 0 -2px 0 var(--QLD-color-light-alt-border);
                padding: 1.25rem 0.75rem 1.25rem 0.75rem;
                &.sorting_1 {
                    box-shadow: 0 -2px 0 var(--QLD-color-light-designAccent);
                    background-color: var(--QLD-color-light-background__shade);
                }

            }
        }
    }

    .qld__data-table__cell-left-border {
        border-left: 1px solid var(--QLD-color-light-border);
    }

    .qld__data-table__cell-right-border {
        border-right: 1px solid var(--QLD-color-light-border);
    }

    &.qld__data-table__col-1-left-border tr td:nth-child(1), //needs to go after merging with tables
    &.qld__data-table__col-2-left-border tr td:nth-child(2),
    &.qld__data-table__col-3-left-border tr td:nth-child(3),
    &.qld__data-table__col-4-left-border tr td:nth-child(4),
    &.qld__data-table__col-5-left-border tr td:nth-child(5),
    &.qld__data-table__col-6-left-border tr td:nth-child(6),
    &.qld__data-table__col-7-left-border tr td:nth-child(7),
    &.qld__data-table__col-8-left-border tr td:nth-child(8),
    &.qld__data-table__col-9-left-border tr td:nth-child(9),
    &.qld__data-table__col-10-left-border tr td:nth-child(10) {
        border-left: 1px solid var(--QLD-color-light-border);
    }

    &.qld__data-table__col-1-right-border tr td:nth-child(1), //needs to go after merging with tables
    &.qld__data-table__col-2-right-border tr td:nth-child(2),
    &.qld__data-table__col-3-right-border tr td:nth-child(3),
    .qld__data-table__header {
        color: var(--QLD-color-dark-system-underline);
        box-shadow: inset 0 -1px 0 var(--QLD-color-light-border);
        .qld__display-md {
            font-size: 1rem;
        }
    }

    &.qld__data-table__col-4-right-border tr td:nth-child(4), //needs to go after merging with tables
    &.qld__data-table__col-5-right-border tr td:nth-child(5),
    &.qld__data-table__col-6-right-border tr td:nth-child(6),
    &.qld__data-table__col-7-right-border tr td:nth-child(7),
    &.qld__data-table__col-8-right-border tr td:nth-child(8),
    &.qld__data-table__col-9-right-border tr td:nth-child(9),
    &.qld__data-table__col-10-right-border tr td:nth-child(10) {
        border-right: 1px solid var(--QLD-color-light-border);
    }

        border-radius: $QLD-border-radius-sm;

        &:not(:first-child) {
            @include QLD-space(margin-top, 1.5unit);
        }

        overflow-x: hidden;


    &.qld__data-table--scroll { //needs to go after tables come in place or if Kenny asks for non scroll
        border: 1px solid var(--QLD-color-light-border);
        border-radius: $QLD-border-radius-sm;
        max-height: 640px;
        /* Max height for mobile devices based on average available screen space on mobile devices */
        overflow-y: auto;

        table {
            caption {
                position: sticky;
                top: 0;
                min-height: 78px;
                /* 78px height so it can cater for additional information detailing table order under the caption text */

                &+thead {
                    top: 78px;
                }
            }

            thead,
            tfoot {
                position: sticky;
            }

            thead {
                inset-block-start: 0;
                /* "top" */
                background-color: var(--QLD-color-light-background);
            }

            tfoot {
                inset-block-end: 0;
                /* "bottom" */
            }
        }


        .qld__body--dark &,
        .qld__body--dark-alt & {
            border: 1px solid var(--QLD-color-dark-border);

            table {
                thead {
                    text-align: left;
                    background-color: var(--QLD-color-dark-background);
                }
            }
        }
    }

    & {
        width: 100%;
        overflow: auto;
        border: 1px solid var(--QLD-color-light-border);
        border-radius: $QLD-border-radius-sm;
        overflow-x: hidden;

    }

    input[type=text],
    input[type=search],
    .qld__text-input { //needs to stay
        color: var(--QLD-color-light-text);
        border: 2px solid var(--QLD-color-light-alt-border);
        background-color: var(--QLD-color-light-background);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .top { //needs to stay
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 12px;
        align-items: center;
        gap: 16px;

        @include QLD-media(md) {
            flex-direction: row;
        }
    }

    .top { //needs to stay
        box-shadow: inset 0 -1px 0 var(--QLD-color-light-border);
    }

    .dataTables_length>label>select { //needs to stay
        border: 2px solid var(--QLD-color-light-alt-border);
        background-color: var(--QLD-color-light-background);
        background-image: url("data:image/svg+xml;charset=utf-8,#{$chevronDown}");
        background-color: transparent;
        padding: 0.75rem 2rem 0.75rem 1rem;
        margin-left: 0.75rem;
    }

    .qld__btn--search { //needs to stay
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .dataTables_wrapper { //needs to stay
        .qld__data-table-item-numbers {
            color: $QLD-color-system-grey-dark;

            &-total {
                font-weight: 700;
            }

            &-heading {
                color: var(--QLD-color-light-heading);
                font-weight: 700;
            }
        }

        .dataTables_info {
            padding-top: 0;
        }

        .bottom {
            gap: 24px;
        }
    }

    &.qld__table--striped {

        .qld__table__body,
        tbody {
            .qld__table__row:nth-last-child(odd),
            tr:nth-last-child(odd) {
                background-color: var(--QLD-color-light-background__shade);
            }
            
        }

        .qld__table__row:nth-last-child(odd),
        tr:nth-last-child(odd) {
            .qld__data-table__cell,
            td {
                &.sorting_1 {
                    background-color: rgba(black, 0.05);
                }
            }
        }
    }

    .qld__body--dark &,
    .qld__body--dark-alt &{

        span.qld__caption {
            color: var(--QLD-color-dark-text__muted);
        }

        .dataTables_wrapper {
            .qld__data-table-item-numbers {
                color: var(--QLD-color-dark-text__muted);


                &-heading {
                    color: var(--QLD-color-dark-heading);
                }
            }

        }

        .top {
            box-shadow: inset 0 -1px 0 var(--QLD-color-dark-border);
        }

        input[type=text],
        input[type=search],
        .qld__text-input {
            color: var(--QLD-color-dark-text);
            border: 2px solid var(--QLD-color-dark-alt-border);
            background-color: var(--QLD-color-dark-background);
        }

        .dataTables_length>label>select {
            border: 2px solid var(--QLD-color-dark-alt-border);
            background-color: var(--QLD-color-dark-background);
            background-image: url("data:image/svg+xml;charset=utf-8,#{$chevronDownDark}");
        }

        .qld__data-table__header {

            color: var(--QLD-color-light-system-underline);
            box-shadow: inset 0 -1px 0 var(--QLD-color-dark-border);

        }

        .qld__data-table__cell,
        td { //needs to stayl
            box-shadow: inset 0 0 -$QLD-border-width-thin 0 var(--QLD-color-dark-border);
            &.sorting_1 {
                background-color: var(--QLD-color-dark-background__shade);
            }
        }

        .qld__data-table__header,
        th {
            box-shadow: inset 0 0 -$QLD-border-width-default 0 var(--QLD-color-dark-alt-border);
            color: var(--QLD-color-dark-heading);
            background-color: var(--QLD-color-dark-background);

            &.sorting_asc,
            &.sorting_desc { //needs to stayl
                background-color: var(--QLD-color-dark-background__shade);
                box-shadow: inset 0 -2px 0 var(--QLD-color-dark-designAccent);
            }
            
            &.sorting_desc { //needs to stayl
                background-image: url("data:image/svg+xml;charset=utf-8,#{$arrowDownDark}");
            }

            &.sorting_asc { //needs to stayl
                background-image: url("data:image/svg+xml;charset=utf-8,#{$arrowUpDark}");
            }
        }

        thead>tr>th {              

            background-image: url("data:image/svg+xml;charset=utf-8,#{$arrowUpDowDark}");


            &:hover,
            &:focus {
                box-shadow: inset 0 -2px 0 var(--QLD-color-dark-button__hover);
            }

            &:active {
                box-shadow: inset 0 -2px 0 var(--QLD-color-dark-designAccent);
            }

            color: var(--QLD-color-dark-hover-underline);

        }

        .qld__data-table__head,
        thead {
            tr {
                &:nth-child(2) {
                    background-color: var(--QLD-color-dark-background__shade);
                }
            }

        }

        .qld__data-table__header,
        th {
            color: var(--QLD-color-dark-heading);
            -webkit-box-shadow: inset 0 -2px 0 var(---QLD-color-dark-designAccent);
            box-shadow: inset 0 -2px 0 var(--QLD-color-dark-designAccent);

            tr {
                &:nth-child(2) {
                    background-color: var(--QLD-color-dark-background__shade);
                }
            }
        }

        .qld__data-table__cell,
        td {
            box-shadow: inset 0 0 -$QLD-border-width-thin 0 var(--QLD-color-dark-border);
        }

        caption,
        .qld__data-table__caption {
            color: var(--QLD-color-dark-heading);
            box-shadow: inset 0 -1px 0 var(--QLD-color-dark-border);
            background-color: var(--QLD-color-dark-background);
        }

        tfoot {
            background-color: var(--QLD-color-dark-background);
            box-shadow: 0 1px 0 var(--QLD-color-dark-border);

            th, tr th {
                box-shadow: 0 -2px 0 var(--QLD-color-dark-alt-border);

                &.sorting_1 { //needs to stayl
                    box-shadow: 0 -2px 0 var(--QLD-color-dark-designAccent);
                    background-color: var(--QLD-color-dark-background__shade);
                }
            }
        }

        .qld__data-table__cell-left-border {
            border-left: 1px solid var(--QLD-color-dark-border);
        }

        .qld__data-table__cell-right-border {
            border-right: 1px solid var(--QLD-color-dark-border);
        }

        .bottom {
            a {
                color: var(--QLD-color-dark-text);
            }
        }

        &.qld__data-table__col-1-left-border tr td:nth-child(1),
        &.qld__data-table__col-2-left-border tr td:nth-child(2),
        &.qld__data-table__col-3-left-border tr td:nth-child(3),
        &.qld__data-table__col-4-left-border tr td:nth-child(4),
        &.qld__data-table__col-5-left-border tr td:nth-child(5),
        &.qld__data-table__col-6-left-border tr td:nth-child(6),
        &.qld__data-table__col-7-left-border tr td:nth-child(7),
        &.qld__data-table__col-8-left-border tr td:nth-child(8),
        &.qld__data-table__col-9-left-border tr td:nth-child(9),
        &.qld__data-table__col-10-left-border tr td:nth-child(10) {
            border-left: 1px solid var(--QLD-color-dark-border);
        }

        &.qld__data-table__col-1-right-border tr td:nth-child(1),
        &.qld__data-table__col-2-right-border tr td:nth-child(2),
        &.qld__data-table__col-3-right-border tr td:nth-child(3),
        .qld__data-table__header {
            color: var(--QLD-color-light-system-underline);
            box-shadow: inset 0 -1px 0 var(--QLD-color-dark-border);
        }

        &.qld__data-table__col-1-right-border tr td:nth-child(1),
        &.qld__data-table__col-2-right-border tr td:nth-child(2),
        &.qld__data-table__col-3-right-border tr td:nth-child(3),
        &.qld__data-table__col-4-right-border tr td:nth-child(4),
        &.qld__data-table__col-5-right-border tr td:nth-child(5),
        &.qld__data-table__col-6-right-border tr td:nth-child(6),
        &.qld__data-table__col-7-right-border tr td:nth-child(7),
        &.qld__data-table__col-8-right-border tr td:nth-child(8),
        &.qld__data-table__col-9-right-border tr td:nth-child(9),
        &.qld__data-table__col-10-right-border tr td:nth-child(10) {
            border-right: 1px solid var(--QLD-color-dark-border);
        }

        // &.qld__data-table--contained { 
            border: 1px solid var(--QLD-color-dark-border);
        // }

        &.qld__table--striped {
            .qld__table__body,
            tbody {
                .qld__table__row:nth-last-child(odd),
                tr:nth-last-child(odd) {
                    background-color: var(--QLD-color-dark-background__shade);
                }

                .qld__table__row:nth-last-child(odd),
                tr:nth-last-child(odd) {
                    .qld__data-table__cell,
                    td {
                        &.sorting_1{ //needs to stayl
                            background-color: rgba(black, 0.05);
                        }
                    }
                }
            }
        }

    }


    .qld__body--dark-alt &{
        
        .qld__data-table__cell,
        td {
            &.sorting_1 { //needs to stayl
                background-color: var(--QLD-color-dark-alt-background__shade);
            }
        }

        .qld__data-table__header,
        th {
            box-shadow: inset 0 0 -$QLD-border-width-default 0 var(--QLD-color-dark-border);
            color: var(--QLD-color-dark-heading);
            background-color: var(--QLD-color-dark-alt-background);

            &.sorting_asc,
            &.sorting_desc { //needs to stayl
                background-color: var(--QLD-color-dark-alt-background__shade);
            }

        }

        .qld__data-table__header,
        th {
            box-shadow: inset 0 0 -$QLD-border-width-default 0 var(--QLD-color-dark-border);
            color: var(--QLD-color-dark-heading);
            background-color: var(--QLD-color-dark-alt-background);

           
            
        }

        caption,
        .qld__data-table__caption {
            color: var(--QLD-color-dark-heading);
            box-shadow: inset 0 -1px 0 var(--QLD-color-dark-border);
            background-color: var(--QLD-color-dark-alt-background);
        }

        tfoot {
            background-color: var(--QLD-color-dark-alt-background);
            box-shadow: 0 1px 0 var(--QLD-color-dark-border);

            th, tr th {
                box-shadow: 0 -2px 0 var(--QLD-color-dark-border);

                &.sorting_1 { //needs to stayl
                    box-shadow: 0 -2px 0 var(--QLD-color-dark-designAccent);
                    background-color: var(--QLD-color-dark-alt-background__shade);
                }

            }
        }

        input[type=text],
        input[type=search],
        .qld__text-input {
            color: var(--QLD-color-dark-text);
            border: 2px solid var(--QLD-color-dark-border);
            background-color: var(--QLD-color-dark-alt-background);
        }

        .dataTables_length>label>select {
            border: 2px solid var(--QLD-color-dark-border);
            background-color: var(--QLD-color-dark-alt-background);
            background-image: url("data:image/svg+xml;charset=utf-8,#{$chevronDownDark}");
        }

    }

}