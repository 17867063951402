//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// {{component_name}}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------
.qld__body .qld__code {
    display: block;

    .qld__code-copy--button {
        @include QLD-space(padding, 0.3125unit 0.8125unit);
        min-width: initial;
        position: relative;
        text-decoration: none;
        

        &-text {
            @include QLD-space('margin-left', .5unit);
            
            
            @include QLD-media(sm, down) {
                display: none;
            }
        }

        .clean {
            display: flex;

            i {
                @include QLD-space('font-size', 1.25unit);
                @include QLD-space('margin', 0.125unit);
            }
        }

        .dirty {
            display: none;

            i {
                @include QLD-space('font-size', 1.5unit);   
            }
        }

        &.copied {
            background-color: var(--QLD-color-light-background__shade);
            border-color: var(--QLD-color-light-background__shade);
            color: var(--QLD-color-light-text);

            i {
                color: $QLD-color-success;
            }

            .clean {
                display: none;
            }

            .dirty {
                display: flex;
            }
        }

        &:focus,
        &:hover {
            box-shadow: none;
            text-decoration: none;

            .qld__code-copy--button-text {
                @include QLD-underline('dark','noUnderline','buttonText');
            }

            
            @include QLD-media(sm, down) {
                text-decoration: underline;
                .qld__code-tooltip {
                    display: block;
                    bottom: calc(100% + 8px);
                }
            }
        }
    }

    .qld__code-header {
        background-color: var(--QLD-color-dark-alt-background__shade);
        border-radius: $QLD-border-radius-xs $QLD-border-radius-xs 0 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include QLD-space('padding', .75unit 1unit);
        @include QLD-space('border-bottom', solid var(--QLD-color-dark-alt-border) 0.0625unit);

        &-left {
            display: flex;
            align-items: center;

            a {
                display: inline-block;
                border-radius: $QLD-border-radius-xs;
                @include QLD-space('margin-right', 1.25unit);

                i {
                    color: var(--QLD-color-dark-alt-button);
                    @include QLD-space('font-size', 1.25unit);
                    @include QLD-space('margin-right', .5unit);
                }

                text-underline-offset: var(--QLD-underline__offset);
                color: var(--QLD-color-dark-link);
                -webkit-text-decoration-color: var(--QLD-color-dark-underline);
                text-decoration-color: var(--QLD-color-dark-underline);
                text-decoration-thickness: var(--QLD-underline__thickness-thin);

                &:hover {
                    text-underline-offset: var(--QLD-underline__offset);
                    -webkit-text-decoration-color: var(--QLD-color-dark-hover-underline);
                    text-decoration-color: var(--QLD-color-dark-hover-underline);
                    text-decoration-thickness: var(--QLD-underline__thickness-thick);
                }
            }
        }
    }

    .qld__code-toggle {
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        z-index: 1;

        &.qld__body--alt {
            background-color: var(--QLD-color-light-alt-background);
        }

        &.qld__body--dark {
            background-color: var(--QLD-color-dark-background);
        }
        
        &.qld__body--dark-alt {
            background-color: var(--QLD-color-dark-alt-background);
        }

        &-button {
            background-color: var(--QLD-color-dark-alt-background__shade);
            border: 0;
            border-radius: $QLD-border-radius-xs $QLD-border-radius-xs 0 0;
            color: var(--QLD-color-dark-text);
            cursor: pointer;
            @include QLD-space('padding', .75unit 1unit);

            i {
                color: var(--QLD-color-dark-alt-button);
                @include QLD-space('font-size', 1.25unit);
                @include QLD-space('margin-left', .5unit);
                transition: transform 0.25s ease-in;
            }

            &:focus {
                outline: 2px solid var(--QLD-color-light-focus);
                outline-offset: 2px;
            }
        }

        + .qld__code-header {
            border-radius: $QLD-border-radius-xs 0 0 0;
        }

        &:not(.qld__code-preview ~ .qld__code-toggle) {
            justify-content: flex-start;
            + .qld__code-header {
                border-radius: 0 $QLD-border-radius-xs 0 0;
            }
            .qld__code-toggle-button {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
    }

    .qld__code-preview {
        position: relative;

        &-body {
            @include QLD-container-padding($QLD-grid-gutter-md, horizontal);
            margin: 0;

            @include QLD-media(md) {
                @include QLD-container-padding($QLD-grid-gutter-lg, horizontal); 
            }
        }

        &-colours {
            align-items: center;
            background-color: var(--QLD-color-light-background__shade);
            border-radius: 0 $QLD-border-radius-xs 0 0;
            color: var(--QLD-color-light-text);
            width: 100%;
            @include QLD-space('padding', 1.0625unit 0.875unit);

            @include QLD-media(md) {
                display: flex;
                @include QLD-space('padding', 1.0625unit 1unit);
            }

            &-label {
                font-weight: 600;
                @include QLD-space('padding-right', 0.75unit);
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                padding-left: 0;
                @include QLD-space('margin-top', 0.625unit);

                @include QLD-media(md) {
                    @include QLD-space('margin-top', 0);
                }

                li {
                    display: flex;
                    align-items: center;
                    @include QLD-space('margin', 0.1875unit 1unit 0.1875unit 0);

                    label {
                        color: var(--QLD-color-light-text);
                        font-weight: 400;
                        @include QLD-space('margin', 0);
                        @include QLD-space('padding-left', 2.5unit);
                    }
                }
            }
        }

        &-tab {
            background-color: var(--QLD-color-dark-alt-background__shade);
            border-radius: $QLD-border-radius-xs $QLD-border-radius-xs 0 0;
            bottom: 100%;
            color: var(--QLD-color-dark-text);
            font-weight: 600;
            display: inline-block;
            position: absolute;
            @include QLD-space('font-size', 0.875unit);
            @include QLD-space('line-height', 1unit);
            @include QLD-space('padding', 0.25unit 1unit);
        }


    }

    // Single line type
    &.singleline {
        border-radius: $QLD-border-radius-xs;
        display: flex;
        width: 100%;
        @include QLD-box-shadow(2);

        code {
            border-radius: $QLD-border-radius-xs 0 0 $QLD-border-radius-xs;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include QLD-space('padding', .625unit 1unit);
        }

        .qld__code-copy--button {
            border-radius: 0 $QLD-border-radius-xs $QLD-border-radius-xs 0;
        }
    }

    // Multi line type
    &.multiline {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include QLD-space('max-width', 65unit);

        &.with-preview {
            display: inline-grid;
            background-color: var(--QLD-color-light-background);
            border-radius: $QLD-border-radius-xs;
            @include QLD-box-shadow(2);
            @include QLD-space('margin-top', 1.5unit);
        }

        &:not(.with-preview) {
            .qld__code-toggle-button,
            .qld__code-header, code {
                @include QLD-box-shadow(2);
            }
        }

        code {
            border-radius: 0 0 $QLD-border-radius-xs $QLD-border-radius-xs;
            overflow: hidden;
            position: relative;
            transition: max-height 0.5s ease-in-out;
            @include QLD-space('padding', 1.25unit 1unit);
        }

        &.show_less {
            code {

                &:before {
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(2, 42, 80, 0.2) 86.21%);
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    width: 100%;
                    @include QLD-space('height', 3.75unit);
                }
            }

            .qld__code-toggle-button i {
                transform: rotate(-180deg);
            }
        }
    }
}

.qld__code--inline {
    display: inline;
    position: relative;

    code {
        border: 0;
        border-radius: $QLD-border-radius-xs;
        cursor: pointer;
        display: inline-block;
        text-align: left;
        @include QLD-space('padding', .125unit .5unit);

        &:focus,
        &:hover {
            background-color: var(--QLD-color-light-button__hover);

            + .qld__code-tooltip {
                display: block;
            }
        }

        &:focus {
            @include QLD-space('border', solid var(--QLD-color-light-focus) 0.125unit);
        }
    }
}

.qld__code-tooltip {
    display: none;
    background: var(--QLD-color-light-background);
    border-radius: $QLD-border-radius-xs;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    @include QLD-space('padding', .25unit 1.5unit);
    @include QLD-box-shadow(3);

    &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: #ffffff transparent transparent transparent;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
    }
}

main .qld__body #content .qld__code .qld__code-preview-body.qld__body {
    @include QLD-space('margin', 0);
    @include QLD-space('padding-bottom', 1.5unit);

    &.qld__body--alt,
    &.qld__body--dark,
    &.qld__body--dark-alt {
        @include QLD-space('padding-bottom', 1.5unit);
    }
}