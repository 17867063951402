//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// {{component_name}}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__search{
	&__info{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        &-query{
            @include QLD-media(md, 'down') {
               .qld__display-lg{ 
                    font-size: 1rem;
               }
            }
    
            &-term{
                font-style: italic;
            }
        }

        &-inner{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            border-bottom: 1px solid var(--QLD-color-light-alt-border);
            align-items: baseline;
        }
        
        &-summary{
            @include QLD-space(font-size, .875unit);
            margin-top: .5rem;
            @include QLD-space(margin-bottom, 1unit);
            
            @include QLD-media(md) {
                @include QLD-space(font-size, 1unit);
            }
        }

        &-summary-text{
            font-weight: bold;
            color: var(--QLD-color-light-heading);
        }
        &-summary-matching{
            font-weight: bold;
        }
    }
    

    &__sort{
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        margin-left: auto;
        font-size: $QLD-font-size-mobile-xs;

        @include QLD-media(md) {
            font-size: $QLD-font-size-desktop-sm;
        }
        
        &-text{
            font-weight: bold;
            color: var(--QLD-color-light-heading);
            @include QLD-space(padding-right, .75unit);
            white-space: nowrap;
        }

        select{
            max-width: 200px;
        }
    }

    &__filters{
        @include QLD-space(padding-top, 1.25unit);
        display: flex;
        flex-direction: column;

        @include QLD-media(md) {
            flex-direction: row;
        
        }
    
        @include QLD-media(lg) {
            @include QLD-space(padding-top, 1.5unit);
        }
      
        &-title{
            font-weight: bold;
            display: block;
            white-space: nowrap;
            @include QLD-space(padding-right, 1unit);
            @include QLD-space(padding-bottom, .5unit);
            @include QLD-media(md) {
                @include QLD-space(padding-top, .25unit);
                @include QLD-space(padding-bottom, 0unit);
                align-self: center;
            }
               
        }

        &-list{
            margin: 0;
        }

    }
    
    &__results &__result{
        margin: 0;
        @include QLD-space(padding-top, 1.5unit);
        @include QLD-space(padding-bottom, 1.5unit);
        border-bottom: 2px solid var(--QLD-color-light-border);

        &:last-child{
            border: none;
        }

        &-header{
            h3{
                margin-bottom: .5rem;
            }
               
        }
        &-inner{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
        }

        &-type{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            @include QLD-space(font-size, .875unit);
            margin-bottom: .5rem;

            @include QLD-media(lg) {
                position: absolute;
                right: 0;
                width: auto;
                flex-direction: column;
                @include QLD-space(padding-top, 0unit);
            }

            &-name{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--QLD-color-light-heading);
                @include QLD-space(margin-right, .75unit);

                @include QLD-media(lg) {
                    flex-direction: row-reverse;
                }
            }
            .qld__tag{
                margin: 0;

                @include QLD-media(lg) {
                    @include QLD-space(margin-top, .5unit);
                }
            }

            svg{
                width: 1rem;
                height: 1rem;
                @include QLD-space(margin-right, .5unit);
                color: var(--QLD-color-light-alt-button);

                @include QLD-media(lg) {
                    @include QLD-space(margin-right, 0unit);
                    @include QLD-space(margin-left, .5unit);
                }
            }

        }

        &-breadcrumbs{
            margin-top: 0;
            margin-bottom: 0.5rem;
            
            .qld__link-list{
                font-size: .875rem;
            }
            > .qld__link-list > li{
                padding: 0;
            }

            li:after{
                @include QLD-space(margin,0 .25unit);
                
            }
        }

        *:not([type="hidden"]) &-summary{
            @include QLD-space(font-size, .875unit);
            @include QLD-space(margin-top, .5unit);
            @include QLD-media(lg) {
                @include QLD-space(font-size, 1unit);
            }

            .qld__enhanced-search__result-date{
                color: var(--QLD-color-light-text__muted);
            }
        }
    }

    &__results-list{
        padding: 0;
    }


    &__related{
        background-color: var(--QLD-color-light-background)--shade;
        @include QLD-space(margin-top, 1.5unit);
        @include QLD-space(padding-top, 1.5unit);
        @include QLD-space(padding-bottom, 1.5unit);
        @include QLD-space(padding-left, 2unit);
        @include QLD-space(padding-right, 2unit);
        @include QLD-space(
            border-top,
            solid 0.25unit var(--QLD-color-light-designAccent)
        );

        &-title{
            @include QLD-space(font-size, 1.25unit);
            @include QLD-space(padding-top, 1unit);
            @include QLD-space(padding-bottom, .5unit);
            &:first-letter{
                text-transform: capitalize;
            }
            &-query{
                font-weight: bold;
            }
        }
    }


    &__tips{
        background-color: var(--QLD-color-light-background)--shade;
        @include QLD-space(margin-top, 2unit);
        @include QLD-space(padding-top, 1.5unit);
        @include QLD-space(padding-bottom, 1.5unit);
        @include QLD-space(padding-left, 2unit);
        @include QLD-space(padding-right, 2unit);
        @include QLD-space(
            border-top,
            solid 0.25unit var(--QLD-color-light-designAccent)
        );
        &-text{
            font-weight: bold;
        }
        
    }
    
}
