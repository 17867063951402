//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Link List
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__link-columns {
    // display: block;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    @include QLD-fontgrid(sm);
    font-weight: bold;
    overflow: hidden;

    * + &{
        @include QLD-space(margin, 1.5unit 0);
    }
    &:last-child{
        @include QLD-space(margin-bottom, 0);
    }

    > li{
        @include QLD-space(padding,1unit .25unit 1unit .25unit );
        box-shadow: 0 $QLD-border-width-thin 0 var(--QLD-color-light-border);
        display: flex;
        justify-content: space-between;
        position: relative;
        flex: 0 0 100%;

        a{
            @include QLD-underline('light','noUnderline');
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        &::after {
            @include QLD-space(width, 1.25unit);
            @include QLD-space(height, 1.25unit);
            @include QLD-space( margin, 0 0.5unit 0 0.5unit );
            content: "";
            flex: 0 0 auto;
            transition: margin 0.2s ease;
            mask-image: QLD-svguri($QLD-icon-arrow-right) ;
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: var(--QLD-color-light-alt-button);
            
            .ie8 &,
            .lt-ie8 & {
                content: " → ";
            }
        }

        &:hover{
            a{
                color: var(--QLD-color-light-link);
            }

            &::after{
                @include QLD-space(margin, 0 0 0 1unit);
                background-color: var(--QLD-color-light-alt-button__hover);
            }
        }

        &.qld__link-columns__all-link {
            @include QLD-fontgrid(sm);
            @include QLD-space(font-size, 1unit);
            font-weight: bold;
            justify-content: flex-end;
            box-shadow: inset 0 0 - $QLD-border-width-thin 0 transparent;
            flex: 0 0 100% !important;

    
            &::after {
                display: none;
            }
        }
    }
    @include QLD-media(lg) {
        &.qld__link-columns--2-col{
            @include QLD-space(column-gap, 2unit);

            > li{
                flex: 0 0 calc(50% - 16px);
                align-self: flex-start;
           
            }

            a {
                break-inside: avoid;
            }
        }

        &.qld__link-columns--3-col {
            @include QLD-space(column-gap, 2unit);
            column-count: 3;

            > li{
                flex: 0 0 calc(33.22% - 22px);
                align-self: flex-start;
           
            }

            a {
                break-inside: avoid;
            }
        }
    }
    

    .qld__body--alt &{
        > li{
            box-shadow:0 $QLD-border-width-thin 0 var(--QLD-color-light-alt-border);

            &.qld__link-columns__all-link {
                box-shadow:0 $QLD-border-width-thin 0 transparent;

                &::after {
                    display: none;
                }
            }
        }
    }

    .qld__body--dark &,
    .qld__main-nav--dark &{
        > li{
            box-shadow:0 $QLD-border-width-thin 0 var(--QLD-color-dark-border);
            a{
                @include QLD-underline('dark','noUnderline');
            }
            &:hover{
                &::after{
                    background-color: var(--QLD-color-dark-alt-button__hover);
                }
            }

            &::after{
                background-color: var(--QLD-color-dark-alt-button);

                
            }

            &.qld__link-columns__all-link {
                box-shadow:0 $QLD-border-width-thin 0 transparent;

                &::after {
                    display: none;
                }
            }
        }
    }

    .qld__body--dark-alt &,
    .qld__main-nav--dark-alt &{
        > li{
            box-shadow:0 $QLD-border-width-thin 0 var(--QLD-color-dark-alt-border);

            a{
                @include QLD-underline('dark','noUnderline');
            }

            &:hover{
                &::after{
                    background-color: var(--QLD-color-dark-alt-button__hover);
                }
            }

            &::after{
                background-color: var(--QLD-color-dark-alt-button);
                
            }

            &.qld__link-columns__all-link {
                box-shadow:0 $QLD-border-width-thin 0 transparent;

                &::after {
                    display: none;
                }
            }
        }
    }
}

.qld__body {
    h1 + .qld__link-columns,
    h2 + .qld__link-columns,
    h3 + .qld__link-columns,
    h4 + .qld__link-columns,
    h5 + .qld__link-columns  {
        @include QLD-space(margin-top, 1.5unit);
    }
}
