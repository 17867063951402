//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Banner
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__banner {
    color: var(--QLD-color-light-text);
    background-color: var(--QLD-color-light-background);
    background-position: center center;
    background-repeat: repeat;
    background-size: auto;
    overflow: hidden;
    padding: 0;
    position: relative;

    & &__content {
        color: var(--QLD-color-light-text);
        position: relative;
        
        @include QLD-space(padding-top, 2unit);
        @include QLD-space(padding-bottom, 2unit);
    
        @include QLD-media(lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include QLD-space(padding-top, 3unit);
            @include QLD-space(padding-bottom, 3unit);
        }

        @include QLD-media(xl) {
            @include QLD-space(padding-top, 4unit);
            @include QLD-space(padding-bottom, 4unit);
        }

        h1 {
            color: var(--QLD-color-light-heading);
            // line-height: 40px;
            // font-weight: bold;
            // @include QLD-space(font-size, 2unit);
            @include QLD-space(margin, 0);

            @include QLD-media(md) {
                @include QLD-space(margin-top, 0);
            }
        }

        p {
            margin: 0;
        }

        * + p {
            @include QLD-space(margin-top, 1unit);
        }

        .qld__banner__heading {
            @include QLD-space(font-size, 1.75unit);
            @include QLD-space(line-height, 2.25unit);
            // @include QLD-space(padding, 0.5unit 1unit);
            // border-left: 4px solid var(--QLD-color-light-designAccent);
            display: inline-block;
            margin: 0;
            border-left: 0px;
            padding: 0px;
    
            @include QLD-media(lg) {
                @include QLD-space(font-size, 2.5unit);
                line-height: 52px;
            }
    
            &__wrapper {
                // display: flex;
                // flex-direction: column;
                // align-items: flex-start;

                display: block;
                @include QLD-space(padding, .5unit 1.25unit);
            }
    
            &--dark {
                display: inline;
                background-color: var(--QLD-color-dark-background);
                color: var(--QLD-color-dark-heading);
                // box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
                box-shadow: 8px 0px 0 6px var(--QLD-color-dark-background), -8px 0px 0 6px var(--QLD-color-dark-background), -12px 0px 0 6px var(--QLD-color-light-designAccent), 0px 4px 16px rgba(0, 0, 0, .3);
                -webkit-box-decoration-break: clone;
                -ms-box-decoration-break: clone;
                -o-box-decoration-break: clone;
                box-decoration-break: clone;
            }
    
            &--light {
                display: table;
                background-color: var(--QLD-color-light-background__shade);
                color: var(--QLD-color-light-text);
                font-weight: 300;
                // box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);  
                box-shadow: 8px 0px 0 6px var(--QLD-color-light-background), -8px 0px 0 6px var(--QLD-color-light-background), -12px 0px 0 6px var(--QLD-color-light-designAccent), 0px 4px 16px rgba(0, 0, 0, .3); 
                -webkit-box-decoration-break: clone;
                -ms-box-decoration-break: clone;
                -o-box-decoration-break: clone;
                box-decoration-break: clone;
                @include QLD-space(margin-top, 0.76unit);
            }
        }

        .qld__banner__content--body.qld__abstract {
            @include QLD-space(margin-top, 1unit);
            @include QLD-space(margin-bottom, 0unit);
    
            @include QLD-media(lg) {
                @include QLD-space(margin-top, 1.5unit);
                @include QLD-space(font-size, 1.5unit);
                @include QLD-space(line-height, 2unit);
            }
    
        }
        .qld__banner__content--cta {
            display: flex;
            flex-direction: column;
            @include QLD-space(margin-top, 0.5unit);

            @include QLD-media(sm) {
                flex-direction: row;
            }
            li{
                @include QLD-space(margin, 0unit);
                @include QLD-media(sm) {
                    &:first-of-type{
                        @include QLD-space(margin-right, 2unit);
                    }
                }
                
            }
            a{
                @include QLD-space(margin, 1unit 0unit 0unit 0unit);
            }
            .qld__btn{
                width: 100%;

                @include QLD-media(sm) {
                    width: auto;
                }
            }
           
            @include QLD-media(lg) {
                flex-direction: row;
            }
        }

        
    }

    &__main {
        flex-grow: 1;

        &.row {
            flex-direction: row;
        }
    }

    &__sub-heading {
        font-weight: 300;
        // display: inline-block;
        display: block;
    }

    //Base hero image 
    .qld__banner__hero {
        background-color: var(--QLD-color-light-background__shade);
        padding: 0;

        @include QLD-media(md) {
            background-color: transparent;
            @include QLD-space(padding-left, 1unit);
            position: absolute;
            left: 50%;
            height: 100%;

        }
        @include QLD-media(lg) {
            left: 58.33%;
            @include QLD-space(padding-left, 1.25unit);
        }
        
        &.qld__banner__hero--scale{

            @include QLD-media(md) {
                @include QLD-space(padding-left, 1unit);
                @include QLD-space(padding-right, 1unit);
                @include QLD-space(padding-top, 1unit);
                @include QLD-space(padding-bottom, 1unit);
            }
            .qld__banner__image{
                background-size: contain;
                &.qld__banner__image--background{
                    background-size: cover;
                }
                @include QLD-media(md) {
                    background-position: right center;
                }
            }
            
        }

        &.qld__banner__hero--fix-right {
            @include QLD-media(md) {
                left: auto;
                right: calc(50% - 50vw);
                padding-right: 0;
            }
        }

        &.qld__banner__hero--crop {
            @include QLD-media(md) {
                height: 100%;
            }
        }

        &.qld__banner__hero--sm {
            @include QLD-media(md) {
                display: none;
            }
        }

        &.qld__banner__hero--lg {
            display: none;

            @include QLD-media(md) {
                display: block;
            }
        }
    }

    //Base image styling
    .qld__banner__image {
        display: block;
        max-width: none;
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        padding-bottom: 56.25%; // 16:9 ratio

        &--background{
            @include QLD-space(padding, 0unit);
            @include QLD-space(height, 10unit);
        }

        @include QLD-media(md) {
            padding-bottom: 0;
            height: 100%;
            
        }

        @include QLD-media(md) {
            vertical-align: middle;
            @include QLD-space(left, 0);
        }


        &.qld__banner__image--mobile-hide{
            display: none;
            @include QLD-media(md) {
                display: block;
            }
        }

        &.qld__banner__image--desktop-hide{
            @include QLD-media(md) {
                display: none;
            }
        }
        

        &.qld__banner__image--sm {
            @include QLD-media(md) {
                display: none;
            }
        }

        &.qld__banner__image--lg {
            display: none;

            @include QLD-media(md) {
                display: block;
            }
        }

        +.qld__banner__content .qld__banner__heading__wrapper {
            @include QLD-space(margin-top, -3unit);

            @include QLD-media(md) {
                @include QLD-space(margin-top, 0);
            }
        }
    }

    //with breadcrumbs
    &.qld__banner--breadcrumbs{
        .qld__banner__content{
            @include QLD-space(padding-top, 0unit);
            @include QLD-space(padding-bottom, 2unit);
    
            @include QLD-media(md) {
                @include QLD-space(padding-top, 1.5unit);
                @include QLD-space(padding-bottom, 2unit);
            }
            @include QLD-media(lg) {
                @include QLD-space(padding-top, 3unit);
                @include QLD-space(padding-bottom, 4unit);
            }
        }
    }

    //With Hero image
    &.qld__banner--has-hero{
        .qld__banner__content{
            @include QLD-space(padding-top, 1unit);
            @include QLD-media(md) {
                @include QLD-space(padding-top, 2unit);
            }
            @include QLD-media(lg) {
                @include QLD-space(padding-right, 2unit);
                @include QLD-space(padding-top, 3unit);
            }
        }

        .qld__banner__content .qld__banner__heading__wrapper{
            @include QLD-space(margin-top, -4unit);
            
            @include QLD-media(md) {
                @include QLD-space(margin-top, 0);
            }
        }
    }

    //Hero image right aligned to page
    &.qld__banner--hero-right {
        .qld__banner__wrapper{
            position: initial;
        }
        .qld__banner__hero{
            @include QLD-space(padding, 0unit);
            @include QLD-media(xl) {
                left: 56.7%;
            }
        }
        .qld__banner__image{
            @include QLD-media(md) {
                width: 50vw;
                max-width: 50vw;
                position: relative;
                background-position: left center;
            }
            @include QLD-media(lg) {
                max-width: 45vw;
                width: 45vw;
            }
            @include QLD-media(xl) {
                max-width: 33vw;
                width: 33vw;
                left: 11vw;
            }

            @media (min-width: 1600px){
                max-width: 34vw;
                width: 34vw;
                left: 10vw;
            }
        }
    }

    //Base search
    &.qld__banner--search{
        overflow: visible;

        .qld__banner__content{
            @include QLD-space(padding-bottom, 1unit);

            @include QLD-media(md) {
                padding-bottom: 1rem;
            }

            @include QLD-media(lg) {
                padding-bottom: 3rem;
            }
        }

        .qld__banner__search{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 2rem;

            @include QLD-media(md) {
                padding-bottom: 2rem;
            }

            @include QLD-media(lg) {
                padding-bottom: 3rem;
            }
        }
    }
    
    &.qld__banner--search.qld__banner--breadcrumbs{
        .qld__banner__content{
            @include QLD-media(md) {
                padding-bottom: 1.5rem;
            }
            @include QLD-media(lg) {
                padding-bottom: 3rem;
            }
        }
    }

    .qld__banner__breadcrumbs--desktop {
        @include QLD-media(md) {
            @include QLD-space(margin-bottom, 1.5unit);
            ~*:last-child {
                @include QLD-space(margin-bottom, auto);
            }
        }
    }

    &--alt{
        background-color: var(--QLD-color-light-alt-background);

        .qld__banner__content {
            color: var(--QLD-color-light-text);

            h1 {
                color: var(--QLD-color-light-heading);
            }

            .qld__banner__heading{
                &--light{
                    background-color: var(--QLD-color-light-background);
                }
            }
        }

        .qld__banner__hero {
            background-color: var(--QLD-color-light-alt-background__shade);
    
            @include QLD-media(md) {
                background-color: transparent;
            }
        }
    }

    &--dark{
        background-color: var(--QLD-color-dark-background);

        .qld__banner__content {
            color: var(--QLD-color-dark-text);

            h1 {
                color: var(--QLD-color-dark-heading);
            }
            .qld__banner__heading{
                // border-left: 4px solid var(--QLD-color-dark-designAccent);

                &--dark {
                    background-color: var(--QLD-color-dark-background__shade);
                    box-shadow: 8px 0px 0 6px var(--QLD-color-dark-background__shade), -8px 0px 0 6px var(--QLD-color-dark-background__shade), -12px 0px 0 6px var(--QLD-color-dark-designAccent), 0px 4px 16px rgba(0, 0, 0, .3);
                }
        
                &--light {
                    background-color: var(--QLD-color-light-background);
                    box-shadow: 8px 0px 0 6px var(--QLD-color-light-background), -8px 0px 0 6px var(--QLD-color-light-background), -12px 0px 0 6px var(--QLD-color-dark-designAccent), 0px 4px 16px rgba(0, 0, 0, .3); 
                }
            }
        }

        .qld__banner__hero {
            background-color: var(--QLD-color-dark-background__shade);
            
            @include QLD-media(md) {
                background-color: transparent;
            }
        }
    }

    &--dark-alt{
        background-color: var(--QLD-color-dark-alt-background);

        .qld__banner__content {
            color: var(--QLD-color-dark-text);

            h1 {
                color: var(--QLD-color-dark-heading);
            }
            .qld__banner__heading{

                &--dark {
                    background-color: var(--QLD-color-dark-background);
                }
                &--light{
                    background-color: var(--QLD-color-light-background);
                }
            }
        }

        .qld__banner__hero {
            background-color: var(--QLD-color-dark-alt-background__shade);
            
            @include QLD-media(md) {
                background-color: transparent;
            }
        }
    }


}

.qld__banner__wrapper {
    display: flex;
    align-items: stretch;
    min-height: 72px;
    position: relative;
}



//Not sure if needed
.qld__banner {
    
    &--padded {
        @include QLD-space(padding-bottom, 2.5unit);
        @include QLD-media(md) {
            @include QLD-space(padding-top, 3unit);
            @include QLD-space(padding-bottom, 3unit);
        }
    }

    


    // &.qld__banner--search {
    //     @include QLD-space(padding-bottom, 0);
    //     position: relative;
    //     overflow: visible;

    //     +.qld__body {
    //         @include QLD-space(padding-top, 2unit);

    //         @include QLD-media(lg) {
    //             @include QLD-space(padding-top, 4unit);
    //         }
    //     }

    //     &:before {
    //         content: '';
    //         background-color: var(--QLD-color-light-background);
    //         width: 100vw;
    //         height: 1.5rem;
    //         position: absolute;
    //         bottom: 0;
    //         left: 0;

    //         @include QLD-media(lg) {
    //             left: calc(50% - 50vw);
    //             height: 2.25rem;
    //         }
    //     }

    //     .qld__search {
    //         @include QLD-space(margin, 1.5unit auto 0 auto);
    //         border-radius: 4px;
    //         box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    //         position: relative;
    //         width: 100%;

    //         @include QLD-media(lg) {
    //             @include QLD-space(margin-top, 2unit);
    //             max-width: 83.3333%;
    //         }

    //         .qld__text-input {
    //             border-color: var(--QLD-color-light-button);

    //             @include QLD-media(lg) {
    //                 @include QLD-space(height, 4.5unit);
    //             }
    //         }

    //         .qld__search__btn {
    //             @include QLD-media(lg) {
    //                 width: 124px;
    //             }
    //         }

    //         .qld__btn {
    //             @include QLD-media(lg) {
    //                 @include QLD-space(height, 4.5unit);
    //                 font-size: 1.5rem;
    //             }
    //         }
    //     }
        
    // }

}