//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Widgets
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__widgets {
    @include QLD-space(bottom, 0.75unit);
    @include QLD-space(right, 0.75unit);
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;

    .qld__btn--floating {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
        display: inline-block;
    }
}

.qld__widgets + .qld__footer {
    // @include QLD-space(padding-bottom, 4unit);

    @include QLD-media(xl) {
        @include QLD-space(padding-bottom, 4unit);
    }
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Back to top
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

body a.qld__btn.qld__btn--floating.qld__btn--back-to-top {
    // @include QLD-underline('dark','noUnderline','buttonText','noVisited');
    @include QLD-space(margin-top, 0.5unit);
    background-color: var(--QLD-color-light-background__shade);
    border-color:  var(--QLD-color-light-background__shade);
    color: var(--QLD-color-light-link);
    padding: 0.5rem 1rem;
    
    &:after {
        @include QLD-space( width, 1unit );
        @include QLD-space( height, 1unit );
        @include QLD-space( margin, 0 0.25unit );
        content: '↑';
        color: var(--QLD-color-light-alt-button);
        display: inline-block;
        width: auto;
        height: auto;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: var(--QLD-color-light-alt-background__shade);
        border-color:  var(--QLD-color-light-alt-background__shade);
        text-decoration-color: var(--QLD-color-light-link);
        &::after{
            content: '↑';
            color: var(--QLD-color-light-alt-button__hover);
        }
    }
}

@media print {
    .qld__widgets {
        display: none !important;
    }
}