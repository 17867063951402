//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Global Alert
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__global-alert_include {
    max-height:0;
    transition:max-height 1s ease-in;
    overflow:hidden;
}

.qld__global-alert {
    padding:5px 0 5px 0;

    .container-fluid {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    &.qld__global-alert--critical {
        background-color: $QLD-color-error;
        color: $QLD-color-system-white;
        font-size:pxToEm(13);
        & + & {
            border-top: 1px solid #8A1220;
        }
        a {
            text-decoration-line: solid;
            text-decoration-thickness: var(--QLD-underline__thickness-thin);
            text-underline-offset: var(--QLD-underline__offset);
            text-decoration-skip-ink: auto;

            color: $QLD-color-system-white;
            text-decoration-color: $QLD-color-system-white;
            span {
                color: $QLD-color-system-white;
                text-decoration-color: $QLD-color-system-white;
                &:hover,
                &:visited {
                    color: $QLD-color-system-white;
                    text-decoration-color: $QLD-color-system-white;
                }
            }

            &:hover, 
            &:visited{
                text-decoration-color: $QLD-color-system-white;
                text-decoration-thickness: var(--QLD-underline__thickness-thick); 
                .qld__icon{
                    color: $QLD-color-system-white;
                }
            }
        }
        
        .qld__icon{
            color: $QLD-color-system-white;
        }
        
    }
    
    &.qld__global-alert--default {
        background-color: $QLD-color-warning;
        color: $QLD-color-system-grey-text;
        font-size:pxToEm(14);
        & + & {
            border-top: 1px solid #B38800;
        }
        a {
            text-decoration-line: solid;
            text-decoration-thickness: var(--QLD-underline__thickness-thin);
            text-underline-offset: var(--QLD-underline__offset);
            text-decoration-skip-ink: auto;

            color: $QLD-color-system-grey-text;
            text-decoration-color: $QLD-color-system-grey-text;
            span {
                color: $QLD-color-system-grey-text;
                text-decoration-color: $QLD-color-system-grey-text;
                &:hover,
                &:visited {
                    color: $QLD-color-system-grey-text;
                    text-decoration-color: $QLD-color-system-grey-text;
                }
            }
            &:hover, 
            &:visited{
                text-decoration-color: $QLD-color-system-grey-text;
                text-decoration-thickness: var(--QLD-underline__thickness-thick); 
                .qld__icon{
                    color: $QLD-color-system-grey-text;
                }
            }
        }
        .qld__icon{
            color: $QLD-color-system-grey-text;
        }
    }
    
    &.qld__global-alert--general {
        background-color: $QLD-color-general;
        color: $QLD-color-system-grey-text;
        font-size:pxToEm(14);
        & + & {
            border-top: 1px solid #5c94c0;
        }
        a {
            text-decoration-line: solid;
            text-decoration-thickness: var(--QLD-underline__thickness-thin);
            text-underline-offset: var(--QLD-underline__offset);
            text-decoration-skip-ink: auto;

            color: $QLD-color-system-grey-text;
            text-decoration-color: $QLD-color-system-grey-text;
            span {
                color: $QLD-color-system-grey-text;
                text-decoration-color: $QLD-color-system-grey-text;
                &:hover,
                &:visited {
                    color: $QLD-color-system-grey-text;
                    text-decoration-color: $QLD-color-system-grey-text;
                }
            }

            &:hover, 
            &:visited{
                text-decoration-color: $QLD-color-system-grey-text;
                text-decoration-thickness: var(--QLD-underline__thickness-thick); 
                .qld__icon {
                    color: $QLD-color-system-grey-text;
                }
            }
        }
        
        .qld__icon {
            color: $QLD-color-system-grey-text;
        }
    }

    .qld__global-alert__icon {
        line-height:1;
        margin-top: 2px;
    }
    
    .qld__global-alert__main {
        display:flex;
        margin:6px 0;
        
        @include QLD-media(sm) {
            margin:14px 0;
        }
        
        @include QLD-media(md) {
            margin:6px 0;
            align-items: center;
        }
    }
    
    .qld__global-alert__content {
        display: flex;
        flex-direction: column;
        padding:0 16px 0 16px;
        
        @include QLD-media(md) {
            flex-direction: row;
            padding:0 48px 0 16px;
        }
    }
    
    .qld__global-alert__action { 
        flex-shrink: 0;
        font-weight: bold;
        margin-top: 0.75rem;
        &:first-child{
            margin: 0;
        }
        
        a {
            display: flex;
            align-items: center;
            &:hover{
                svg{
                    transform: translateX(4px);
                }
            }
            &:focus{
                outline: 3px solid $QLD-color-dark-alt-background__shade;
				outline-offset: 2px;
                border-radius: 4px;
            }
        }
        
        svg {
            display:inline-block;
            flex-shrink: 0;
            margin-left:8px;
            transition: all .3s ease;
        }
        
        
        @include QLD-media(md) {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
    
    .qld__global-alert__close {
        margin-left:auto;
        position:relative;
        line-height:1;
        display:flex;
        // align-items: center;
        text-align: center;
        justify-content: center;
        
        button {
            background-color:rgba(0, 0, 0, 0.1);
            cursor:pointer;
            height:32px;
            width:32px;
            padding:0;
            border-radius:20px;
            border:0;
            position: relative;
            transition: all .3s ease;
            
            svg {
                width:12px;
                height:12px;
                margin:50%;
                padding:0;
                transform: translate(-50%, -50%);
                
            }
            
            &:hover {
                transform: scale(1.3);
            }
            &:focus{
                outline: 3px solid $QLD-color-dark-alt-background__shade;
				outline-offset: 2px;
            }

            @include QLD-media(md) {
                right: -20px;
            }

            @include QLD-media(lg) {
                right: 0;
            }
        }
    }
}

@media print {
    .qld__global_alert_include {
        display: none !important;
    }
}